import Login from '../pages/Login';
import { Auth } from '../layouts/auth';
import Forgot from '../pages/Login/Forgot';
import { Routes, Route} from "react-router-dom";
import NewAccount from '../pages/Login/NewAccount';
import Signature from './../pages/Signature/index';
import Certificate from './../pages/Certificate/index';

export default function SignRoutes() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Auth />} >
          <Route index element={<Login />} />
          <Route path="/forgot" element={<Forgot />} />
          <Route path="/new-account" element={<NewAccount />} />
        </Route>
        <Route path="/certificate/:id/:hash" element={<Certificate />} />
        <Route path='/signature/:idProcess/:token' element={<Signature />} />
        <Route path="*" element={<Auth ><Login /></Auth>} />
      </Routes>
    </>
  )
}