/* eslint-disable react-hooks/exhaustive-deps */
import { useFormik } from "formik";
import { toast } from "react-toastify";
import Modal from "@mui/material/Modal";
import { CgUserList } from "react-icons/cg";
import * as api from "../../../services/api";
import { IoBusiness } from "react-icons/io5";
import { GiModernCity } from "react-icons/gi";
import Input from "../../../components/Input";
import { TiBusinessCard } from "react-icons/ti";
import { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlineFieldNumber } from "react-icons/ai";
import { cookies } from "./../../../hooks/useCookies";
import SafeButton from "../../../components/SafeButton";
import { RiMapPinUserFill, RiGovernmentFill } from "react-icons/ri";
import { BsFillTelephoneFill, BsFillMapFill } from "react-icons/bs";
import { InputMaskNormal as InputMask } from "../../../components/Input/InputMask";
import {
  FaCalendar,
  FaUser,
  FaGlobeAmericas,
  FaMapPin,
  FaMapSigns,
} from "react-icons/fa";
import {
  MdSmartphone,
  MdOutlineAlternateEmail,
  MdOutlineDomainVerification,
} from "react-icons/md";
import {
  ModalContainer,
  ChangeModal,
} from "./../../../components/Plan/styles";
export default function NewAccount() {
  const dateRef = useRef(null);

  const navigate = useNavigate();

  const [loading, setLoading] = useState();
  const [company, setCompany] = useState(false);
  const [valueInput, setValueInput] = useState({});
  const [formCompany, setFormCompany] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [confirmChangeModal, setConfirmChangeModal] = useState(false);
//   const width = useWindowSize2();

//   const initialValueUser = {
//     first_name: "",
//     last_name: "",
//     cpf: "",
//     age: "",
//     email: "",
//     phone: "",
//   };

//   const initialValueCompany = {
//     first_name: "",
//     last_name: "",
//     cpf: "",
//     age: "",
//     email: "",
//     phone: "",
//     site: "",
//     plan: "",
//     cnpj: "",
//     number: "",
//     coupon: "",
//     domain: "",
//     balance: "",
//     complement: "",
//     responsible: "",
//     fantasy_name: "",
//     social_reason: "",
//   };

//   let isCompany = formCompany === true ? initialValueCompany : initialValueUser;

  const apiCep = async (event) => {
    let cep = event.target.value;
    let changedCep = "";

    if (cep.includes("-")) {
      changedCep = cep.replace("-", "");
    }
    if (changedCep.length < 8) {
      setValueInput({
        bairro: "",
        cep: "",
        cidade: "",
        logradouro: "",
        uf: "",
        ddd: "",
        gia: "",
        ibge: "",
        siafi: "",
      });
    }
    if (changedCep.length === 8) {
      await fetch(`https://viacep.com.br/ws/${changedCep}/json`, {
        method: "GET",
      }).then(async (response) => {
        let data = await response.json();
        setValueInput({
          bairro: data.bairro,
          cep: data.cep,
          cidade: data.localidade,
          logradouro: data.logradouro,
          uf: data.uf,
          ddd: data.ddd,
          gia: data.gia,
          ibge: data.ibge,
          siafi: data.siafi,
        });
      });
    }
  };

  const { handleChange, handleSubmit, values } = useFormik({
    initialValues: {
    //   isCompany,
    },
    onSubmit: (values) => {
      setConfirmChangeModal(true);
    },
  });

  function userSubmit(data) {
    if (data.company) {
      data = {
        ...data,
        first_name: values.first_name,
        last_name: values.last_name,
        cpf: values.cpf,
        age: values.age,
        email: values.email,
        phone: values.phone,
      };
    }

    api
      .post("/user/create/", data, "application/json")
      .then((response) => {
        toast.dismiss();
        if (response.status === 201) {
          navigate("../", { replace: true });
          cookies.set("@Safysign:verify-toast", "true");
        } else if (response.response.status === 400) {
          navigate("../", { replace: true });
          toast.error(response.response.data.detail, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error("Tente novamente mais tarde", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(() => {
        setButtonLoading(false);
        toast.error("Tente novamente mais tarde", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  }

  function companySubmit(data) {
    api
      .post("/company/create/", data, "application/json")
      .then((response) => {
        if (response.status === 201) {
          toast.success("Empresa cadastrada com sucesso!", {
            position: toast.POSITION.TOP_RIGHT,
          });
          let idCompany = response.data.detail.detail.id;
          userSubmit({ company: idCompany });
        } else if (
          response.response.status === 400 &&
          response.response.data.detail.users === "0"
        ) {
            alert('oi')
          let IdCompany = response.response.data.detail.id;
          userSubmit({ company: IdCompany });
        } else if (response.response.status === 400) {
          navigate("../", { replace: true });
          toast.error(
            "Já existe uma empresa cadastrada com este cnpj, favor entre em contato com o administrador",
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
        }
      })
      .catch(() => {
        setButtonLoading(false);
        toast.error("Ocorreu um erro, tente novamente mais tarde", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  }

  function submit() {
    setButtonLoading(true);
    if (company) {
      const companyToSubmit = {
        first_name: values.first_name,
        last_name: values.last_name,
        cpf: values.cpf,
        age: values.age,
        email: values.email,
        phone: values.phone,
        address: {
          ...valueInput,
          complement: values.complement,
          company_address_number: values.company_address_number,
        },
        site: values.site,
        cnpj: values.cnpj,
        number: values.number,
        coupon: values.coupon,
        domain: values.domain,
        balance: values.balance,
        responsible: values.responsible,
        fantasy_name: values.fantasy_name,
        social_reason: values.social_reason,
      };
      companySubmit(companyToSubmit);
    } else {
      const userToSubmit = {
        first_name: values.first_name,
        last_name: values.last_name,
        cpf: values.cpf,
        age: values.age,
        email: values.email,
        phone: values.phone,
      };
      userSubmit(userToSubmit);
    }
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }

  return (
    <>
      <div>
        <form onSubmit={handleSubmit}>
          <div>
            <div className="container-button">
              <SafeButton
                type="button"
                onClick={() => {
                  setFormCompany(!formCompany);
                  setCompany(!company);
                }}
                variant="secondary"
              >
                {company ? "Cadastrar como usuário" : "Cadastrar como empresa"}
              </SafeButton>
            </div>
            <div className="container-input">
              <div className="input-wrapper">
                <h3>DADOS DO USUÁRIO</h3>
                <div className="container-input">
                  <div className="input-wrapper">
                    <div className="form-icon">
                      <FaUser size={25} />
                    </div>
                    <Input
                      required
                      type="text"
                      name="first_name"
                      placeholder="Nome"
                      className="full-input"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="input-wrapper">
                    <div className={"form-icon"}>
                      <FaUser size={25} />
                    </div>
                    <Input
                      type="text"
                      name="last_name"
                      placeholder="Sobrenome"
                      className="full-input"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="container-input">
                  <div className="form-icon">
                    <CgUserList size={20} />
                  </div>
                  <InputMask
                    required
                    mask="999.999.999-99"
                    name="cpf"
                    placeholder="CPF"
                    maskChar={null}
                    onChange={handleChange}
                  />
                </div>
                <div className="container-input">
                  <div className="form-icon">
                    <FaCalendar size={20} />
                  </div>
                  <input
                    ref={dateRef}
                    onFocus={() => {
                      dateRef.current.type = "date";
                    }}
                    onBlur={() => {
                      if (
                        dateRef.current.value === undefined ||
                        dateRef.current.value === null ||
                        dateRef.current.value === ""
                      ) {
                        dateRef.current.type = "text";
                      }
                    }}
                    required
                    type="text"
                    maxLength={8}
                    name="age"
                    placeholder="Data de nascimento"
                    className="input-date"
                    onChange={handleChange}
                  />
                </div>
                <div className="container-input">
                  <div className="form-icon">
                    <MdOutlineAlternateEmail size={20} />
                  </div>
                  <Input
                    required
                    type="email"
                    name="email"
                    placeholder="E-mail"
                    className="full-input"
                    onChange={handleChange}
                  />
                </div>
                <div className="container-input">
                  <div className="form-icon">
                    <MdSmartphone size={20} />
                  </div>
                  <InputMask
                    required
                    mask="(99) \9 9999-9999"
                    name="phone"
                    placeholder="Celular"
                    onChange={handleChange}
                  />
                </div>
              </div>
              {company ? (
                <div className="input-wrapper">
                  <h3>DADOS DA EMPRESA</h3>
                  <div className="container-input">
                    <div className="input-wrapper">
                      <div className="form-icon">
                        <IoBusiness size={25} />
                      </div>
                      <Input
                        required={company}
                        type="text"
                        name="social_reason"
                        placeholder="Razão social"
                        className="full-input"
                        onChange={handleChange}
                      />
                    </div>
                    <div className="input-wrapper">
                      <div className={"form-icon"}>
                        <IoBusiness size={25} />
                      </div>
                      <Input
                        require
                        type="text"
                        name="fantasy_name"
                        placeholder="Nome fantasia"
                        className="full-input"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="container-input">
                    <div className="form-icon">
                      <TiBusinessCard size={20} />
                    </div>
                    <InputMask
                      required={company}
                      mask="99.999.999/9999-99"
                      name="cnpj"
                      placeholder="CNPJ"
                      maskChar={null}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="container-input">
                    <div className="form-icon">
                      <MdOutlineDomainVerification size={20} />
                    </div>
                    <Input
                      type="text"
                      name="domain"
                      placeholder="Dominio da empresa. Ex.: minhaempresa.com.br"
                      className="full-input"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="container-input">
                    <div className="form-icon">
                      <FaGlobeAmericas size={20} />
                    </div>
                    <Input
                      type="text"
                      name="site"
                      placeholder="Site da empresa"
                      className="full-input"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="container-input">
                    <div className="input-wrapper">
                      <div className="form-icon">
                        <MdOutlineAlternateEmail size={20} />
                      </div>
                      <Input
                        required={company}
                        type="text"
                        name="responsible"
                        placeholder="E-mail do responsável pela empresa"
                        className="full-input"
                        onChange={handleChange}
                      />
                    </div>
                    <div className="input-wrapper">
                      <div className="form-icon">
                        <BsFillTelephoneFill size={20} />
                      </div>
                      <InputMask
                        mask="(99) 9999-9999"
                        name="number"
                        placeholder="Telefone da empresa"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>

            {company ? (
              <>
                <h3 style={{ paddingTop: "20px" }}>ENDEREÇO EMPRESARIAL</h3>
                <div className="container-input">
                  <div className="input-wrapper">
                    <div className="form-icon">
                      <BsFillMapFill size={20} />
                    </div>
                    <InputMask
                      required
                      mask="99999-999"
                      name="cep"
                      maskChar={null}
                      placeholder="CEP"
                      onChange={apiCep}
                    />
                  </div>
                  <div className="input-wrapper">
                    <div className="form-icon">
                      <FaMapPin size={20} />
                    </div>
                    <Input
                      required={company}
                      disabled
                      type="text"
                      name="logradouro"
                      value={valueInput.logradouro || ''}
                      placeholder="Logradouro"
                      className="full-input"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="input-wrapper">
                    <div className="form-icon">
                      <AiOutlineFieldNumber size={20} />
                    </div>
                    <Input
                      required={company}
                      type="number"
                      name="company_address_number"
                      placeholder="Número"
                      className="full-input"
                      value={values.company_address_number || ''}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="container-input">
                  <div className="input-wrapper">
                    <div className="form-icon">
                      <FaMapSigns size={20} />
                    </div>
                    <Input
                      required={company}
                      disabled
                      type="text"
                      name="bairro"
                      value={valueInput.bairro || ''}
                      placeholder="Bairro"
                      className="full-input"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="input-wrapper">
                    <div className="form-icon">
                      <RiMapPinUserFill size={20} />
                    </div>
                    <Input
                      type="text"
                      name="complement"
                      placeholder="Complemento"
                      value={values.complement || ''}
                      className="full-input"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="container-input">
                  <div className="input-wrapper">
                    <div className="form-icon">
                      <GiModernCity size={20} />
                    </div>
                    <Input
                      required={company}
                      disabled
                      type="text"
                      name="localidade"
                      value={valueInput.cidade || ''}
                      placeholder="Cidade"
                      className="full-input"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="input-wrapper">
                    <div className="form-icon">
                      <RiGovernmentFill size={20} />
                    </div>
                    <Input
                      required={company}
                      disabled
                      type="text"
                      name="uf"
                      value={valueInput.uf || ''}
                      placeholder="UF"
                      className="full-input"
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}

            <div className="container-button">
              <Link to="/" className="link-secondary">
                Fazer login
              </Link>
              <SafeButton type="submit" loading={loading} disabled={loading}>
                Cadastrar
              </SafeButton>
            </div>
          </div>
        </form>
      </div>
      <Modal open={confirmChangeModal}>
        <ModalContainer>
          <ChangeModal>
            <div style={{ fontSize: "2rem" }}>
              <p>Tem certeza que deseja finalizar o cadastro?</p>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                paddingTop: "1rem",
              }}
            >
              <div>
                <SafeButton
                  variant="secondary"
                  onClick={() => {
                    setConfirmChangeModal(false);
                  }}
                  loading={buttonLoading}
                >
                  Cancelar
                </SafeButton>
              </div>
              <div style={{ paddingLeft: "0.5rem" }}>
                <SafeButton
                  variant="primary"
                  loading={buttonLoading}
                  onClick={() => {
                    setButtonLoading(true);
                    submit();
                  }}
                >
                  Finalizar
                </SafeButton>
              </div>
            </div>
          </ChangeModal>
        </ModalContainer>
      </Modal>
    </>
  );
}
