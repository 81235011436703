/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import Card from "../../components/Card";
import { Outlet } from "react-router-dom";
import NavBar from "../../components/NavBar";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./../../hooks/useAuth";
import { ToastContainer } from "react-toastify";
import StatusBar from "../../components/StatusBar";
import useWindowSize from "../../hooks/useWindowSize";
import TermoContrato from "../../components/TermContract";
import { Container, Content, NavContainer } from "./styles";
import Navigation from "./../../components/NavBar/Navigation/index";

export default function Core() {
  const navigate = useNavigate();
  const { width } = useWindowSize();
  const { user } = useAuth();

  useEffect(() => {
    async function checkUser() {
      if (user) {
        if (user.reset_password) {
          navigate("./reset-password", { replace: true });
        }
      }
    }
    checkUser();
  }, [navigate]);

  return (
    <Container>
      <ToastContainer />
      <NavBar />
      <StatusBar />
      <NavContainer>
        {width > 700 ? <Navigation /> : <></>}
        <Content>
          <Card>
            <Outlet />
            {/* <Modal open={bonusModal} size="xs" onClose={() => setBonusModal(false)}>
                <SignUpModalContainer>
                  <GiPartyPopper size='80%' />
                    <SignUpModalText>
                        Parabéns, você foi premiado com o primeiro mês do plano completamente por nossa conta, aproveite nossa plataforma!
                    </SignUpModalText>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <div>
                                <SafeButton
                                    variant='primary'
                                    style={{ width: '90%' }}
                                    onClick={() => setBonusModal(false)}
                                >
                                    Uhu!
                                </SafeButton>
                        </div>
                    </div>
                </SignUpModalContainer>
            </Modal> */}
            {user?.term === true ? <></> : <TermoContrato />}
          </Card>
        </Content>
      </NavContainer>
    </Container>
  );
}
