import { toast } from 'react-toastify'
import * as api from "../services/api"
import { createContext, useLayoutEffect, useState } from 'react'
import { cookies } from '../hooks/useCookies';

const DashContext = createContext({});

export function DashProvider({ children }) {
  const [files, setFiles] = useState()
  const [page, setPage] = useState(false)
  const [open, setOpen] = useState(false)
  const [vault, setVault] = useState(null)
  const [click, setClick] = useState(false)
  const [isEmpty, setIsEmpty] = useState(false)
  const [processName, setProcessName] = useState()
  const [isDropped, setIsDropped] = useState(false)
  const [signatories, setSignatories] = useState([])
  const [allProccess, setAllProccess] = useState({})

  useLayoutEffect(() => {
    const data = cookies.get("@Safysign:allProccess")
    if(Object.keys(allProccess).length === 0 && data) {
      setAllProccess(data)
    }
  }, [allProccess])

  async function getProcessCount() {
    await api
      .get("/process/list/count/")
      .then((response) => {
        if (response.status === 200) {
          let data = response.data;
          setAllProccess(data);
          cookies.set("@Safysign:allProccess", data)
        } else {
          toast.error(
            "Ocorreu um erro ao tentar requerir os processos do usuário",
            { position: toast.POSITION.TOP_RIGHT }
          );
        }
      })
      .catch((erro) => {
        toast.error(
          "Ocorreu um erro ao tentar requerir os processos do usuário",
          { position: toast.POSITION.TOP_RIGHT }
        );
      });
  }

  return (
    <DashContext.Provider
      value={{ page, allProccess, setAllProccess, isDropped, setIsDropped, setPage, files, setFiles, click, setClick, signatories, setSignatories, processName, setProcessName, vault, setVault, isEmpty, setIsEmpty, getProcessCount, open, setOpen }}
    >
      {children}
    </DashContext.Provider>
  );
}

export default DashContext;