import { Link } from "react-router-dom";
import Term from "../TermContract/term";
import Modal from "@mui/material/Modal";
import { FiLogOut } from "react-icons/fi";
import { FaWpforms } from "react-icons/fa";
import { useEffect, useState } from "react";
import Navigation from "../NavBar/Navigation";
import { FaUserCircle } from "react-icons/fa";
import { useAuth } from "../../hooks/useAuth";
import { AiOutlineMenu } from "react-icons/ai";
import { useDash } from './../../hooks/useDash';
import SafeButton from "../../components/SafeButton";
import useWindowSize from "../../hooks/useWindowSize";
import { Header, SideMenu, MyAccount } from "./styles";
// import { BsArrowReturnLeft } from "react-icons/bs";

export default function NavBar() {
  
  const [currentUser, setCurrentUser] = useState()
  const [openAcount, setOpenAcount] = useState(false);
  const [openTermContract, setOpenTermContract] = useState(false);
  
  const { width } = useWindowSize();
  const { user, logout } = useAuth();
  const { page, setIsDropped, setFiles, setClick, setSignatories, setProcessName, setVault, open, setOpen } = useDash();
  
  useEffect(() => {
        setCurrentUser(user)
  }, [user])

  const logo = require("../../assets/images/SAFYSIGN-LOGO-PNG.png");
  const logoIcon = require("../../assets/images/icone.png");
  
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleOpenAcount = () => setOpenAcount(true);
  const handleCloseAcount = () => setOpenAcount(false);
  const handleCloseTermContract = () => setOpenTermContract(false);
  const handleOpenTermContract = () => {
    setOpenTermContract(true);
    setOpenAcount(false);
  };

  const body = (
    <SideMenu>
      <div className="container">
        <Navigation mobile={true} />
      </div>
    </SideMenu>
  );  

  const myAccount = (
    <MyAccount>
      <div className="container">
        <div className="header">
          <img src={logoIcon} alt="logo icon" />
        </div>
        <div className="user">
          <h4>{currentUser?.first_name}</h4>
          <h5>
            {currentUser?.profile === '3'
              ? "Administrador do sistema"
              : currentUser?.profile === '2'
              ? "Administrador de empresa"
              : "Usuário"}
          </h5>
        </div>
        <div className="options">
          <Link to="/profile" onClick={() => setOpenAcount(false)}>
            <div className="buttons">
              <FaUserCircle />
              <p>&nbsp; Minha Conta</p>
            </div>
          </Link>
          {/* <Link to="/dashboard/extrato" onClick={() => setOpenAcount(false)}>
            <div className="buttons">
              <BsTextRight />
              <p>&nbsp; Extrato</p>
            </div>
          </Link> */}
          <div className="buttons" onClick={handleOpenTermContract}>
            <FaWpforms />
            <p>&nbsp; Ler Contrato</p>
          </div>
          {/* <Link to="/dashboard/" onClick={() => setOpenAcount(false)}>
            <div className="buttons">
              <BsArrowReturnLeft />
              <p>&nbsp; Voltar</p>
            </div>
          </Link> */}
        </div>
      </div>
    </MyAccount>
  );

  const modalTermContractReadOnly = (
    <Modal
      open={openTermContract}
      onClose={handleCloseTermContract}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >

        <Term>
           <div className="term">
                <label htmlFor="termo">
                  Você já aceitou os{" "}
                  <b>TERMOS E CONDIÇÕES DOS SERVIÇOS SAFY SIGN</b>.
                </label>
              </div>
              <div className="buttons">
                <SafeButton onClick={handleCloseTermContract}>
                  <p>OK</p>
                </SafeButton>
              </div>
        </Term>
    
    </Modal>
  );

  return (
    <Header>
      {width > 700 ? (
        <>
          <div className="columLogo">
            <Link
              to="/"
              onClick={() => {
                if (page === true) {
                  setIsDropped(false);
                  setFiles(false);
                  setClick(false);
                  setSignatories([])
                  setProcessName()
                  setVault(null)
                }
              }}
            >
              <img src={logo} alt="Safy Sign" />
            </Link>
          </div>
          <div className="colum" style={{cursor:'default'}}>
            <p>
              <strong style={{cursor:'pointer'}} onClick={handleOpenAcount}>{currentUser?.first_name}</strong>,
              seja bem vindo(a).
            </p>
            <FiLogOut style={{cursor:'pointer'}} onClick={logout} />
          </div>
        </>
      ) : (
        <>
          <div className="columLogoMobile">
            <Link to="/">
              <img src={logo} alt="Safy Sign" />
            </Link>
          </div>
          <div className="columMobile">
            <p>
              <strong onClick={handleOpenAcount}>{currentUser?.first_name}</strong>
            </p>
            <AiOutlineMenu onClick={handleOpen} />
            {/* <FiLogOut onClick={logout} /> */}
          </div>
        </>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {body}
      </Modal>
      <Modal
        open={openAcount}
        onClose={handleCloseAcount}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {myAccount}
      </Modal>
      {modalTermContractReadOnly}
    </Header>
  );
}