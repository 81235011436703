import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as api from "../services/api";
import { cookies } from "../hooks/useCookies";
import { useAuth } from "./useAuth";

export function useUserSet() {
  const { user, setUser } = useAuth();
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    const checkUserStatus = async () => {
      if (user && !checked) { 
        try {
          let response;
          if (user.company === null) {
            response = await api.get(`user/retrieve/${user.id}/`);
          } else {
            response = await api.get(`company/retrieve/${user.company}/`);
          }

          if (response.status === 200) {
            const data = response.data.detail;
            if (user.company === null) {
              setUser(prevUser => ({
                ...prevUser,
                plan: data?.plan,
                paid: data?.paid,
                paid_status: data?.paid_status,
                is_bank_slip_payer: data?.is_bank_slip_payer,
              }));
            } else {
              setUser(prevUser => ({
                ...prevUser,
                company_plan: data?.plan,
                company_paid: data?.company_paid ,
                company_paid_status: data?.company_paid_status,
                company_is_bank_slip_payer: data?.company_is_bank_slip_payer
              }));
            }
            cookies.remove("@Safysign:user");
            cookies.set("@Safysign:user", JSON.stringify(user));
            setChecked(true);
          }
        } catch (error) {
          toast.error(error?.response?.data?.detail, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }
    };

    checkUserStatus();
  }, [user,setUser, checked]);
}