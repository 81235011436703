import styled from 'styled-components'
import { colors } from '../../styles/colors'



export const Container = styled.div`
  
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(190px, 1fr));
  justify-items: center;

`
export const Cofre = styled.div`
  background-color: ${colors.gray200};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  flex-direction: column;
  width: 15rem;
  height: 20rem;
  padding: 1rem;
  margin: 1rem;
  border: 1px solid ${colors.gray200};
  border-radius: 16px;
  transition: 0.5s;

  &:hover {
    border-color: ${colors.primary};
    cursor: pointer;
    text-decoration: none;
  }

  & > svg {
    margin-top: 20px;
    font-size: 10rem;
    margin-bottom: 20px;
  }

  & > p {
    display: flex;
    align-items: center;
    height: 46px;
    font-size: 1.6rem;
    justify-items: center;
    justify-content: center;
    justify-self: center;
    text-align: center;
    text-justify: center;
    text-decoration: none;
    color: ${colors.primary};
    font-weight: 400;
    overflow-wrap: anywhere;
  }
`

export const ControlsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 96%;
  height: 50px;
  gap: 10px;

  @media(max-width: 600px){
    justify-content: center;
  }
`

export const ModalContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  @media(max-width: 800px){
    width: 60%;
    flex-direction: column;
    
    & > input {
      width: 100%;
      margin-right: 50%;
    }

    & > button {
      width: 100%;
    }
  }
`

export const ContainerNewSafy = styled.div`
  display:flex;
  background-color: ${colors.gray300};
  margin-top: 320px!important;
  margin: auto;
  align-items: center;
  justify-content: space-between;
  width: 450px;
  height: 100px;
  border-radius: 10px;
  transform: translate(-50% -50%);
  padding-left: 10px;
  padding-right: 10px;
  gap: 5px;

  @media (max-width:457px ){
    width: 90%;
  }

  input {
  background: ${colors.gray200};
  border-radius: 8px;
  height: 40px;
  padding: 0 10px;
  border: none;
  width: 80%;
  }
`
export const ContainerNewSafyDelete = styled.div`
  display:flex;
  background-color: ${colors.gray300};
  margin-top: 320px!important;
  margin: auto;

  align-items: center;
  justify-content: space-between;
  width: 500px;
  height: 100px;
  border-radius: 10px;
  transform: translate(-50% -50%);
  padding-left: 10px;
  padding-right: 10px;
  gap: 5px; 

  @media (max-width:519px ){
    flex-wrap: wrap;
    width: 90%;
    height: auto;
    padding-top: 10px;
    padding-bottom: 10px;
    justify-content: center;
  }
`

