import Term from './term'
import SafeButton from '../SafeButton'
import Modal from '@mui/material/Modal'
import * as api from '../../services/api'
import { useState, useEffect } from 'react'
import { useAuth } from '../../hooks/useAuth'
import { toast, ToastContainer } from 'react-toastify'
import { cookies } from '../../hooks/useCookies'

export default function TermContract() {
  
  const [isChecked, setIsChecked] = useState(false)
  const [open, setOpen] = useState(false)
  
  const { user, setUser, Logout } = useAuth()

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  useEffect(() => {
      !user?.terms && handleOpen()
  }, [user])

  function check(event) {
    event.target.checked ? setIsChecked(true) : setIsChecked(false)
  }

  async function aceitarTermo() {
    const booleanSubmit = {
      "user_terms": true
    }

    api.put(`/user/term/update/${user.id}/`, booleanSubmit)
      .then((response) => {
        if (response?.status === 200) {
          toast.success('Termo aceito com sucesso', {
            position: toast.POSITION.TOP_RIGHT
          })
          let data = cookies.get("@Safysign:user")
          data = {...data, terms: true}
          setUser({...user, terms: true})
          cookies.set("@Safysign:user", JSON.stringify(data))
          handleClose(false)
        }
        else {
          toast.error('Ocorreu um erro ao aceitar o termo, tente novamente mais tarde', {
            position: toast.POSITION.TOP_RIGHT
          })
        }
      })
      .catch((error) => {
        toast.error('Ocorreu um erro ao tentar aceitar o termo, tente novamente mais tarde', {
          position: toast.POSITION.TOP_RIGHT
        })
      }
      )

  }

  return (
    <>
      <ToastContainer />
      <Modal
        open={open}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Term>
            <div className="term">
                <input type="checkbox" name="termo" id="termo" onChange={check} />
                <label htmlFor="termo">
                    Li e estou de acordo com o <b>TERMO E CONDIÇÕES DOS SERVIÇOS SAFY SIGN</b>.
                </label>
                </div>
                <div className="buttons">
                  <SafeButton
                    onClick={Logout}
                    variant="secondary"
                >
                    Recusar
                </SafeButton>
                  <SafeButton
                    onClick={aceitarTermo}
                    blocked={!isChecked}
                    variant="primary"
                  >
                    Aceitar
                  </SafeButton>
                </div>
        </Term>
      </Modal>
    </>
  )
}
