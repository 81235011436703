/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import "./styles.css";
import { Modal } from "rsuite";
import { toast } from "react-toastify";
import * as api from "../../services/api";
import { useParams } from "react-router-dom";
import { BounceLoader } from "react-spinners";
import CertificadoPDF from "./CertificadoPDF";
import { colors } from "./../../styles/colors";
import { getFontByType } from "../../utils/getFont";
import { getTipoPapel } from "../../utils/getMessage";
import { PDFDownloadLink } from "@react-pdf/renderer";
import useWindowSize2 from "../../hooks/useWindowSize2";
import React, { useRef, useEffect, useState } from "react";
import Logo from "../../assets/images/SAFYSIGN-LOGO-PNG.png";
import Brasao from "../../assets/images/SELO_SAFYSIGN-PNG.png";
import { getEventIcon, getEventMessage } from "./../../utils/getMessage";
import SafeButton from "../../components/SafeButton";
// import axios from "axios";
// import MockAdapter from "axios-mock-adapter";

function Certificate() {
  const certificadoRef = useRef();
  const { id, hash } = useParams();

  const [eventsData, setEventsData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [processData, setProcessData] = useState();
  const [certificateData, setCertificateData] = useState();
  // const [base64Signatures, setBase64Signatures] = useState([]);

  const [modalContent, setModalContent] = useState({
    show: true,
    size: "sm",
    content: (
      <>
        <BounceLoader size={30} color="#60159a" />
        <p>Aguarde enquanto preparamos o certificado.</p>
        <BounceLoader size={30} color="#60159a" />
      </>
    ),
  });
  const [base64, setBase64] = useState([]);

  const width = useWindowSize2();

  function dateFormat(date, mode = 1) {
    if (date != null && mode === 1) {
      let splicedArray = date.split("T")[0].split("-");
      let arrDate = date.split("T");

      return (
        splicedArray[2] +
        "/" +
        splicedArray[1] +
        "/" +
        splicedArray[0] +
        " " +
        arrDate[1].split(".")[0]
      );
    } else {
      let splicedArray = date.split("-");

      return splicedArray[2] + "/" + splicedArray[1] + "/" + splicedArray[0];
    }
  }

  // const mockData = {
  //   certification: {
  //     retrieve: {
  //       "80add5ab-576e-4259-bec3-e38439cb57fd": {
  //         b119c4d210aa1e76470367773c8317695b3fb1426a2e4f9be278e2f32e09b7d474ad75e71c1a70038ace2ec18e0af7eef943ba196f22cf7662aec0057e824eb5:
  //           {
  //             user: null,
  //             detail: {
  //               certificate: {
  //                 id: "80add5ab-576e-4259-bec3-e38439cb57fd",
  //                 signatures: [
  //                   {
  //                     email_signature: "Isabely@sqad.com.br",
  //                     ip: "2804:41e0:fd1c:9a00:c4a9:50:55b2:c730",
  //                     latitude: "-23.403510716623558",
  //                     longitude: "-46.52258841992796",
  //                     created_at: "2024-07-05T13:39:50.613769Z",
  //                     name: "Isabely Cardoso figueiredo",
  //                     cpf: "55555555555",
  //                     age: "16/06/2003",
  //                     signature: {
  //                       text: "Isabely Cardoso Figueiredo",
  //                       font: "1",
  //                     },
  //                   },
  //                 ],
  //                 created_at: "2024-07-05T10:39:50.628941-03:00",
  //                 updated_at: "2024-07-05T10:39:50.628950-03:00",
  //                 is_active: true,
  //                 hash: "b119c4d210aa1e76470367773c8317695b3fb1426a2e4f9be278e2f32e09b7d474ad75e71c1a70038ace2ec18e0af7eef943ba196f22cf7662aec0057e824eb5",
  //                 process: "d87334d1-bfcc-46be-89c3-7cccea2ecaf3",
  //               },
  //               process: {
  //                 id: "d87334d1-bfcc-46be-89c3-7cccea2ecaf3",
  //                 documents: [
  //                   {
  //                     id: "7fa497f8-1fc4-45a0-8ae7-3c29bb1a561f",
  //                     created_at: "2024-07-05T10:37:51.212336-03:00",
  //                     updated_at: "2024-07-05T10:37:52.037044-03:00",
  //                     is_active: true,
  //                     name: "HOLERITE ISABELLA JULHO 2024.pdf",
  //                     file: "https://storage-safy-sign.s3.amazonaws.com/private/safy-sign/ad4d9984-9bf2-4504-b768-19f755130602/process/d87334d1-bfcc-46be-89c3-7cccea2ecaf3/documents/HOLERITE%20ISABELLA%20JULHO%202024.pdf/HOLERITE_ISABELLA_JULHO_2024.pdf?AWSAccessKeyId=AKIA5EXS3N4OLZGXDIJE&Signature=8paBJtJWlLCqAZ6LYMfJGh6BNuU%3D&Expires=1720491542",
  //                     final_file:
  //                       "https://storage-safy-sign.s3.amazonaws.com/private/safy-sign/ad4d9984-9bf2-4504-b768-19f755130602/process/d87334d1-bfcc-46be-89c3-7cccea2ecaf3/documents/authenticated/Arquivo_autenticado_-_HOLERITE_ISABELLA_JULHO_2024.pdf?AWSAccessKeyId=AKIA5EXS3N4OLZGXDIJE&Signature=ZcZzCNTPw5eRg%2F9PlG0lpRLHrrI%3D&Expires=1720491542",
  //                     hash: "45bb9886119e1d22b1f675a2e5adda5884ea251049dbb7b0114e8f1efdead4cc",
  //                     created_by: "ad4d9984-9bf2-4504-b768-19f755130602",
  //                   },
  //                 ],
  //                 certificate: {
  //                   id: "80add5ab-576e-4259-bec3-e38439cb57fd",
  //                   hash: "b119c4d2102aec0057e824eb5",
  //                   process: "d87334d1-bfcc-46be-89c3-7cccea2ecaf3",
  //                 },
  //                 signatories: [
  //                   {
  //                     type: 1,
  //                     email: "Isabely@sqad.com.br",
  //                     token:
  //                       "715b6452cb42ebdc711db7a5d15705e5c61b6ebd86ed22a4d883da9f5ae34fe4",
  //                     timestamp: 1720186671.195923,
  //                     signed: true,
  //                   },
  //                 ],
  //                 created_by: {
  //                   id: "ad4d9984-9bf2-4504-b768-19f755130602",
  //                   name: "Cintia Lopes de Jesus",
  //                   email: "financeiro@sqad.com.br",
  //                 },
  //                 company_info: {
  //                   id: "d058f955-fe63-4b3d-b858-54a29f6e159c",
  //                   fantasy_name: "SQUADY",
  //                   social_reason: "SQUADY SOLUCOES TECNOLOGICAS LTDA",
  //                   responsible: "daniel@sqad.com.br",
  //                   cnpj: "46.008.372/0001-65",
  //                 },
  //                 created_at: "2024-07-05T10:37:51.196325-03:00",
  //                 updated_at: "2024-07-05T10:39:50.625746-03:00",
  //                 is_active: true,
  //                 ip: "2804:14c:bf2e:abbd:be86:5dfa:3b06:5bc",
  //                 name: "RECIBO ISA JULHO 2024",
  //                 hash: "c7dec37ac13668d16f71a77655cf6bf591025e292b23926b05c502c15cd79cb0",
  //                 status: "2",
  //                 orderly: true,
  //                 latitude: "-23.4422272",
  //                 longitude: "-46.5272832",
  //                 vault: "344dfda9-1e94-4601-84a0-24cb4414af68",
  //                 company: "d058f955-fe63-4b3d-b858-54a29f6e159c",
  //                 signatures: ["7e212639-a246-4838-a449-87e6d879799a"],
  //               },
  //               events: [
  //                 {
  //                   id: "b21e0957-9be0-4945-8789-e860359cc0c5",
  //                   created_by: "financeiro@sqad.com.br",
  //                   created_at: "2024-07-05T10:37:51.203305-03:00",
  //                   updated_at: "2024-07-05T10:37:51.203314-03:00",
  //                   is_active: true,
  //                   ip: "2804:14c:bf2e:abbd:be86:5dfa:3b06:5bc",
  //                   latitude: "-23.4422272",
  //                   longitude: "-46.5272832",
  //                   event: "1",
  //                   signatory: [
  //                     {
  //                       type: 1,
  //                       email: "Isabely@sqad.com.br",
  //                       token:
  //                         "715b6452cb42ebdc711db7a5d15705e5c61b6ebd86ed22a4d883da9f5ae34fe4",
  //                       timestamp: 1720186671.195923,
  //                     },
  //                   ],
  //                   process: "d87334d1-bfcc-46be-89c3-7cccea2ecaf3",
  //                 },
  //                 {
  //                   id: "47dada76-2a3a-4b43-8d05-94c1f4440bae",
  //                   created_by: "financeiro@sqad.com.br",
  //                   created_at: "2024-07-05T10:39:50.619219-03:00",
  //                   updated_at: "2024-07-05T10:39:50.619229-03:00",
  //                   is_active: true,
  //                   ip: "2804:14c:bf2e:abbd:be86:5dfa:3b06:5bc",
  //                   latitude: "-23.4422272",
  //                   longitude: "-46.5272832",
  //                   event: "2",
  //                   signatory: [
  //                     {
  //                       type: 1,
  //                       email: "Isabely@sqad.com.br",
  //                       token:
  //                         "715b6452cb42ebdc711db7a5d15705e5c61b6ebd86ed22a4d883da9f5ae34fe4",
  //                       timestamp: 1720186671.195923,
  //                     },
  //                   ],
  //                   process: "d87334d1-bfcc-46be-89c3-7cccea2ecaf3",
  //                 },
  //               ],
  //             },
  //           },
  //       },
  //     },
  //   },
  // };

    useEffect(() => {
      async function getCertificadoData() {
        api
          .get(`/certification/retrieve/${id}/${hash}/`)
          .then((response) => {
            if (response?.status === 200) {
              setCertificateData(response?.data.detail.certificate);
              setEventsData(response?.data.detail.events);
              setProcessData(response?.data.detail.process);
              setIsLoading(false);
              response?.data?.detail?.certificate?.signatures?.forEach((item) => {
                return fetch(item?.signature)
                  .then((response) => response.blob())
                  .then((blob) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(blob);

                    reader.onload = () => {
                      setBase64([
                        ...base64,
                        { base64: reader.result, email: item.email_signature },
                      ]);
                    };
                  });
              });
              setModalContent({ ...modalContent, show: false });
            } else {
              toast.error(
                "Ocorreu um erro ao gerar o certificado, tente novamente mais tarde",
                {
                  position: toast.POSITION.TOP_RIGHT,
                }
              );
            }
          })
          .catch((error) => {
            toast.error(
              "Ocorreu um erro ao gerar o certificado, tente novamente mais tarde",
              {
                position: toast.POSITION.TOP_RIGHT,
              }
            );
          });
      }
      getCertificadoData();
    }, []);

  // const mock = new MockAdapter(axios);
  // mock
  //   .onGet(
  //     "/certification/retrieve/80add5ab-576e-4259-bec3-e38439cb57fd/b119c4d210aa1e76470367773c8317695b3fb1426a2e4f9be278e2f32e09b7d474ad75e71c1a70038ace2ec18e0af7eef943ba196f22cf7662aec0057e824eb5"
  //   )
  //   .reply(
  //     200,
  //     mockData.certification.retrieve["80add5ab-576e-4259-bec3-e38439cb57fd"][
  //       "b119c4d210aa1e76470367773c8317695b3fb1426a2e4f9be278e2f32e09b7d474ad75e71c1a70038ace2ec18e0af7eef943ba196f22cf7662aec0057e824eb5"
  //     ]
  //   );

  // useEffect(() => {
  //   async function getCertificadoData() {
  //     try {
  //       const response = await axios.get(
  //         `/certification/retrieve/80add5ab-576e-4259-bec3-e38439cb57fd/b119c4d210aa1e76470367773c8317695b3fb1426a2e4f9be278e2f32e09b7d474ad75e71c1a70038ace2ec18e0af7eef943ba196f22cf7662aec0057e824eb5`
  //       );
  //       if (response.status === 200) {
  //         const data = response.data.detail;
  //         setCertificateData(data.certificate);
  //         setEventsData(data.events);
  //         setProcessData(data.process);
  //         console.log(data.process)
  //         setIsLoading(false);
  //         data.certificate.signatures.forEach((item) => {
  //           fetch(item.signature.text) 
  //             .then((response) => response.blob())
  //             .then((blob) => {
  //               const reader = new FileReader();
  //               reader.readAsDataURL(blob);
  //               reader.onload = () => {
  //                 setBase64((prevBase64) => [
  //                   ...prevBase64,
  //                   { base64: reader.result, email: item.email_signature },
  //                 ]);
  //               };
  //             });
  //         });
  //         setModalContent({ ...modalContent, show: false });
  //       } else {
  //         toast.error(
  //           "Ocorreu um erro ao gerar o certificado, tente novamente mais tarde",
  //           {
  //             position: toast.POSITION.TOP_RIGHT,
  //           }
  //         );
  //       }
  //     } catch (error) {
  //       toast.error(
  //         "Ocorreu um erro ao gerar o certificado, tente novamente mais tarde",
  //         {
  //           position: toast.POSITION.TOP_RIGHT,
  //         }
  //       );
  //     }
  //   }
  //   getCertificadoData();
  // }, []);

  function formataCPF(cpf) {
    cpf = cpf.replace(/[^\d]/g, "");

    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  }

  return (
    <div className="certificado-screen" style={{ overflowY: "hidden" }}>
        <div className="container-button" style={{padding: width[0] <= 425 ? '2rem': '4rem', flexDirection: width[0] <= 500 ? 'column': 'row'}}>
            {!isLoading && (
                <SafeButton
                style={{ fontSize: '16px' }}
                onClick={() => {
                    const newWindow = window.open(
                    processData?.documents[0]?.final_file
                    );
                    if (newWindow) newWindow.opener = null;
                }}
                >
                Acessar Documento
                </SafeButton>
            )}
            {!isLoading && base64.length > 0 && (
                <PDFDownloadLink
                style={{ position: "relative"}}
                document={
                    <CertificadoPDF
                    certificateData={certificateData}
                    eventData={eventsData}
                    processData={processData}
                    />
                }
                fileName={`certificado - ${processData?.documents[0]?.name}.pdf`}
                className="download-button"
                >
                {({ loading }) =>
                    loading ? "Carregando Documento..." : "Salvar como PDF"
                }
                </PDFDownloadLink>
            )}

        </div>
      <div
        className="certificado"
        ref={certificadoRef}
        style={{ width: width[0] > 1000 ? "67%" : "100%" }}
      >
        <header style={{padding: width[0] <= 768 ? '0 2rem': '0 12rem'}}>
          <img
            src={Logo}
            alt="SafySign"
            style={{ width: width[0] <= 768 ? "15rem" : "20rem", marginTop: '1rem' }}
          />
          <img src={Brasao} alt="Certificado" style={{ width: "110px" }} />
        </header>
        <div className="content-container" style={{width: width[0] <= 768 ? '95%': '85%'}}>
          <h2
            style={
              width[0] >= 425
                ? { color: "#60159a", fontSize: "20px" }
                : { color: "#60159a", fontSize: "20px" }
            }
          >
            Certificado de Conclusão
          </h2>
          {!isLoading && (
            <>
              <div className="info-container" style={{ width: "90%" }}>
                <div className="processo-info-container">
                  <p style={{marginTop: '0'}}>
                    <span className="strong">Nome do Processo: </span>
                    {processData?.name}
                  </p>
                  <p style={{marginTop: '0'}}>
                    <span className="strong">Identificação do Processo: </span>
                    {processData?.id}
                  </p>
                  <p style={{marginTop: '0'}}>
                    <span className="strong">Quantidade de Documentos: </span>
                    {processData?.documents?.length}
                  </p>
                  <p style={{marginTop: '0'}}>
                    <span className="strong">Criado por: </span>
                    {processData?.created_by?.name} (
                    {processData?.created_by?.email})
                  </p>
                  {processData?.company_info !== null ? (
                    <p style={{marginTop: '0'}}>
                      <span className="strong">Instituição: </span>
                      {processData?.company_info === null
                        ? ""
                        : processData?.company_info.social_reason}
                    </p>
                  ) : (
                    ""
                  )}
                  {processData?.company_info !== null ? (
                    <p style={{marginTop: '0'}}>
                      <span className="strong">CNPJ: </span>
                      {processData?.company_info === null
                        ? ""
                        : processData?.company_info.cnpj}
                    </p>
                  ) : (
                    ""
                  )}
                  <p style={{marginTop: '0'}}>
                    <span className="strong">Data de Criação: </span>
                    {dateFormat(processData?.created_at)}
                  </p>
                  <p style={{marginTop: '0'}}>
                    <span className="strong">Data de Finalização: </span>
                    {dateFormat(processData?.updated_at)}
                  </p>
                  <p style={{marginTop: '0', wordBreak: 'break-all'}}>
                    <span className="strong">Hash256: </span>
                    {processData?.documents[0]?.hash}
                  </p>
                </div>
                <div className="assinaturas-container">
                  <h3>Assinaturas</h3>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      flexDirection: "column",
                    }}
                  >
                    {certificateData.signatures.map((assinaturaObj, index) => {
                      return (
                        <div key={index} className="assinatura-container">
                          <div style={{ width: "100%" }}>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-evenly",
                                paddingBottom: "15px",
                                paddingTop: "15px",
                                borderRadius: "5px",
                                border: `3px dashed #8b8b8b40`,
                                paddingLeft: '2rem',
                                paddingRight: '2rem',
                                flexDirection: width[0] <= 600 ? 'column-reverse': 'row'
                              }}
                            >
                              {assinaturaObj?.signature.constructor().toString()
                                .length !== 15 ? (
                                <div style={{borderBottom: `2px solid ${colors.darker}`, width: width[0] > 600 ? "50%" : "100%",}}>
                                  <img
                                    src={assinaturaObj?.signature}
                                    style={{
                                      width:"9rem",
                                      marginRight: "5%",
                                    }}
                                    alt=""
                                  />
                                </div>
                              ) : (

                                <div style={{ borderBottom: `2px solid ${colors.darker}`, width: width[0] > 600 ? "50%" : "100%",}}>
                                <p
                                  style={{
                                    fontFamily: getFontByType(
                                      assinaturaObj?.signature?.font
                                    ),
                                    fontSize:
                                      width[0] >= 1024
                                        ? '2.5rem'
                                        : '2rem',
                                    marginRight: "5%",
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    position: 'relative',
                                    color: '#5d5d5d'
                                  }}
                                >
                                  {assinaturaObj?.signature?.text}
                                </p>
                                </div>
                              )}
                              <div style={{ marginLeft: "5px", color: '#5d5d5d', width: width[0] < 600 ? '100%': '50%', display: 'flex', justifyContent: 'center' }}>
                                {assinaturaObj?.signature
                                  .constructor()
                                  .toString().length !== 15 ? (
                                  <div style={{ width: "max-content" }}>
                                    <p style={{ marginTop: "2px" }}>
                                      <span style={{ fontWeight: 900 }}>
                                        Tipo:{" "}
                                      </span>
                                      {processData?.signatories.map((item) => {
                                        if (
                                          item?.email ===
                                          assinaturaObj?.email_signature
                                        ) {
                                          return getTipoPapel(
                                            parseInt(item?.type)
                                          );
                                        }
                                      })}
                                    </p>
                                    <p style={{ marginTop: "2px" }}>
                                      <span style={{ fontWeight: 900 }}>
                                        CPF:{" "}
                                      </span>
                                      {assinaturaObj?.cpf}
                                    </p>
                                    <p style={{ marginTop: "2px" }}>
                                      <span style={{ fontWeight: 900 }}>
                                        Nome:{" "}
                                      </span>
                                      {assinaturaObj?.name}
                                    </p>
                                  </div>
                                ) : (
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      flexDirection: "row",
                                    }}
                                  >
                                    <div style={{ marginLeft: "5px" }}>
                                      <div style={{ width: "max-content" }}>
                                        <p style={{ marginTop: "2px" }}>
                                          <span style={{ fontWeight: 900 }}>
                                            Tipo:{" "}
                                          </span>
                                          {processData?.signatories.map(
                                            (item) => {
                                              if (
                                                item?.email ===
                                                assinaturaObj?.email_signature
                                              ) {
                                                return getTipoPapel(
                                                  parseInt(item?.type)
                                                );
                                              }
                                            }
                                          )}
                                        </p>
                                        <p style={{ marginTop: "2px" }}>
                                          <span style={{ fontWeight: 900 }}>
                                            CPF:{" "}
                                          </span>
                                          {assinaturaObj?.cpf}
                                        </p>
                                        <p style={{ marginTop: "2px" }}>
                                          <span style={{ fontWeight: 900 }}>
                                            Nome:{" "}
                                          </span>
                                          {assinaturaObj?.name}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="documentos-info-container">
                  <h3>Documentos</h3>
                  <ul>
                    {processData.documents.map((document) => (
                      <li key={document.id}>
                        <p style={{marginTop: '0px'}}>
                          <span className="strong">Documento: </span>
                          {document.name}
                        </p>
                        <p style={{marginTop: '0px'}}>
                          <span className="strong">Identificador: </span>
                          {document.id}
                        </p>
                        <p style={{marginTop: '0px'}}>
                          <span className="strong">
                            Data de envio ao sistema:{" "}
                          </span>

                          {dateFormat(document.created_at)}
                        </p>
                        <p style={{ wordBreak: "break-all", marginTop: '0px' }}>
                          <span className="strong" style={{ fontSize: "14px" }}>
                            Hash SHA256:{" "}
                          </span>
                          {document?.hash}
                        </p>
                        <p style={{ wordBreak: "break-all", marginTop: '0px'}}>
                          <span
                            className="strong"
                            style={{ fontSize: "14px", wordBreak: "break-all" }}
                          >
                            Hash SHA512:{" "}
                          </span>
                          {certificateData?.hash}
                        </p>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="signatarios-info-container">
                  <h3>Signatários</h3>
                  <ul>
                    {processData?.signatories?.map((signatory) => (
                      <li key={signatory?.token}>
                        <h4 style={{fontSize: width[0] > 1024 ? '18px': '18px', lineHeight: '1.2'}}>
                          {certificateData?.signatures?.map((item) => {
                            if (item?.email_signature === signatory?.email) {
                              return item?.name;
                            }
                          })}{" "}
                          ({getTipoPapel(signatory?.type)})
                        </h4>
                        <p style={{marginTop: '0px'}}>
                          <span className="strong">CPF: </span>
                          {certificateData?.signatures?.map((item) => {
                            if (item?.email_signature === signatory?.email) {
                              return formataCPF(item?.cpf);
                            }
                          })}
                        </p>
                        <p style={{marginTop: '0px'}}>
                          <span className="strong">Data de nascimento: </span>
                          {certificateData?.signatures?.map((item) => {
                            if (item?.email_signature === signatory?.email) {
                              return dateFormat(item?.age, 2).includes(
                                "undefined/"
                              )
                                ? dateFormat(item?.age, 2).replaceAll(
                                    "undefined/",
                                    ""
                                  )
                                : dateFormat(item?.age, 2);
                            }
                          })}
                        </p>
                        <p style={{marginTop: '0px'}}>
                          Assinou o documento o documento em{" "}
                          <span className="strong">
                            {certificateData?.signatures?.map((item) => {
                              if (item?.email_signature === signatory?.email) {
                                return dateFormat(item?.created_at);
                              }
                            })}
                          </span>{" "}
                          com o IP:
                          <span className="strong">
                            {certificateData?.signatures?.map((item) => {
                              if (item?.email_signature === signatory?.email) {
                                return item?.ip;
                              }
                            })}
                          </span>{" "}
                          na localização Latitude:{" "}
                          <span className="strong">
                            {certificateData?.signatures?.map((item) => {
                              if (item?.email_signature === signatory?.email) {
                                return item?.latitude;
                              }
                            })}
                          </span>
                          {", Logitude:"}
                          <span className="strong">
                            {certificateData?.signatures?.map((item) => {
                              if (item?.email_signature === signatory?.email) {
                                return item?.longitude;
                              }
                            })}
                          </span>
                        </p>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="eventos-container" style={{ width: "90%" }}>
                <h3>Histórico</h3>
                {eventsData.map((event) => (
                  <div className="evento" style={{ flexDirection: width[0] < 450 ? "column" : "", gap: width[0] < 450 ? "1rem" : ""}} key={event.id}>
                    <div
                      className="evento-data-container"
                      style={{
                        // width:
                        //   width[0] >= 768
                        //     ? "16%"
                        //     : width[0] >= 346
                        //     ? "29%"
                        //     : "36%",
                        paddingLeft: width[0] >= 425 ? "3px" : "0",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <span
                        className="strong"
                        style={{ fontSize: width[0] >= 346 ? "14px" : "13px", width: 'max-content' }}
                      >
                        {dateFormat(event?.created_at).split(" ")[0]}
                      </span>
                      <span>{dateFormat(event?.created_at).split(" ")[1]}</span>
                    </div>
                    <div
                      className="evento-icon-container"
                      style={{
                        width: "10%",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {getEventIcon(event?.event)}
                    </div>
                    <div
                      style={{
                        width:
                          width[0] < 450
                            ? "100%"
                            : "75%",
                            alignItems:  width[0] < 450
                            ? "center" : ""
                      }}
                    >
                      {getEventMessage(
                        event?.event,
                        event?.created_by,
                        event?.latitude,
                        event?.longitude,
                        event?.ip
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
        <footer>
          <a href="http://www.safysign.com.br/">www.safysign.com.br</a>
          <p>Todos os direitos reservados a marca SafySign.</p>
        </footer>
      </div>

      <Modal show={modalContent.show} size={modalContent.size}>
        <div className="modal-container">{modalContent.content}</div>
      </Modal>
    </div>
  );
}

export default Certificate;
