export const colors = {
  white: "#ffffff",
  gray400: "#bdbcbc",
  gray300: "#d4d4d4",
  gray200: "#ececec",
  gray100: "#efefef",
  primary: "#60159a",
  secondary: "#9c44dc",
  darker: "#8b8b8b",
  dark: "#222222",
  red: "#c4302b",
  green: "#0e640c",
  gray600: '#5d5d5d'
}