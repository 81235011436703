import React from 'react'
import { Outlet } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import useWindowSize2 from './../../hooks/useWindowSize2';
import logoImg from '../../assets/images/SAFYSIGN-LOGO-PNG.png'
import {
  SignContainer,
  SignInPanel,
  SignFormContainer,
  BorderOffset,
  SingPanelContainer
} from './styles'

export function Auth({children}) {

  const [width] = useWindowSize2()

  return (
    <SignContainer>
      <SingPanelContainer
        width={width}
        mode={window.location.pathname.includes('firstPayPlan')}
      >
        <SignInPanel>
          <ToastContainer />
          <img src={logoImg} alt="SafySign logo" className="logo" />
          <SignFormContainer>
            <Outlet />
            {children ? children : <></>}
          </SignFormContainer>
        </SignInPanel>
        <BorderOffset />
      </SingPanelContainer>
    </SignContainer>
  )
}
