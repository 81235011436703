/* eslint-disable react-hooks/exhaustive-deps */
import { Container } from "./styles";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { useLocation } from "react-router-dom";
import { useDash } from "./../../hooks/useDash";
import React, { useState, useEffect, memo } from "react";
import { toast } from "react-toastify";

function StatusBar() {
  const { status } = useParams();
  const { setIsEmpty, allProccess, getProcessCount } = useDash();
  const { pathname } = useLocation();
  const [classLink, setClassLink] = useState(0);
  const [, setAddMore] = useState(false);
  const { user } = useAuth();

  useEffect(() => {
    getProcessCount();
    setIsEmpty(false);
    updateClassLink();
  }, [status, pathname]);

  function addZero(numberString) {
    const number = parseInt(numberString);
    if (number < 10) {
      return `0${number}`;
    } else {
      return numberString;
    }
  }

  function updateClassLink() {
    return status === "all"
      ? setClassLink(1)
      : status === "pending"
      ? setClassLink(2)
      : status === "finalized"
      ? setClassLink(3)
      : status === "canceled"
      ? setClassLink(4)
      : pathname === "/trash"
      ? setClassLink(5)
      : status === undefined
      ? setClassLink(null)
      : "";
  }

  useEffect(() => {
    getProcessCount();
  }, []);

  function handleClick() {
    if (
      (user.company && user.company_is_bank_slip_payer === true) ||
      user.is_bank_slip_payer === true
    ) {
      toast.info(
        "Entre em contato com o comercial para troca de plano ou adquirir mais saldo!",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    } else {
      getProcessCount();
    }
  }

  return (
    <Container id="container">
      <Link
        to="/process/list/all"
        // onClick={() => setClassLink(1)}
      >
        <div className={classLink === 1 ? "clicked" : ""}>
          <div>
            <p>Totais:</p>
          </div>
          <strong>
            &nbsp;
            {isNaN(
              addZero(
                parseInt(allProccess.detail?.pending) +
                  parseInt(allProccess.detail?.finished) +
                  parseInt(allProccess.detail?.canceled)
              )
            )
              ? "00"
              : addZero(
                  parseInt(allProccess.detail?.pending) +
                    parseInt(allProccess.detail?.finished) +
                    parseInt(allProccess.detail?.canceled)
                )}
          </strong>
        </div>
      </Link>
      <Link to="/process/list/pending" onClick={() => getProcessCount()}>
        <div className={classLink === 2 ? "clicked" : ""}>
          <div>
            <p>Pendentes:</p>
          </div>
          <strong>
            &nbsp;
            {addZero(allProccess.detail?.pending)}
          </strong>
        </div>
      </Link>
      <Link to="/process/list/finalized" onClick={() => getProcessCount()}>
        <div className={classLink === 3 ? "clicked" : ""}>
          <div>
            <p>Finalizados:</p>
          </div>
          <strong>
            &nbsp;
            {addZero(allProccess.detail?.finished)}
          </strong>
        </div>
      </Link>
      <Link to="/process/list/canceled" onClick={() => getProcessCount()}>
        <div className={classLink === 4 ? "clicked" : ""}>
          <div>
            <p>Cancelados:</p>
          </div>
          <strong>
            &nbsp;
            {addZero(allProccess.detail?.canceled)}
          </strong>
        </div>
      </Link>
      <Link to="/trash" onClick={() => getProcessCount()}>
        <div className={classLink === 5 ? "clicked" : ""}>
          <div>
            <p>Excluídos:</p>
          </div>
          <strong>
            &nbsp;
            {addZero(allProccess.detail?.excluded)}
          </strong>
        </div>
      </Link>
      <Link
        to={
          (user.company && user.company_is_bank_slip_payer === true) ||
          user.is_bank_slip_payer === true
            ? ""
            : "/plan"
        }
        onClick={handleClick}
      >
        <div
          id="saldo-container"
          onMouseEnter={() => setAddMore(true)}
          onMouseLeave={() => setAddMore(user.balance === 0)}
        >
          {user.company &&
            (user.company_paid === "3" ||
              user.company_paid === "5") && (
              <div>
                {allProccess.detail?.balance === 0 ? (
                  <div className="containerButton">
                    <span>Contratar mais!</span>
                  </div>
                ) : (
                  <div className="containerButton">
                    <span>Saldo: </span>
                    <span>{addZero(allProccess.detail?.balance)}</span>
                  </div>
                )}
              </div>
            )}
          {!user.company &&
            (user.paid === "3" || user.paid === "5") && (
              <div>
                {allProccess.detail?.balance === 0 ? (
                  <div className="containerButton">
                    <span>Contratar mais!</span>
                  </div>
                ) : (
                  <div className="containerButton">
                    <span>Saldo: </span>
                    <span>{addZero(allProccess.detail?.balance)}</span>
                  </div>
                )}
              </div>
            )}

          {user.company && user.company_paid === "0" ? (
            <span>Pagamento pendente</span>
          ) : (
            <span>{(user.company_paid !== "5" && user.company_paid !== "3") ? user.company_paid_status : <></>}</span>
          )}
          {!user.company && user.paid === "0" ? (
            <span>Pagamento pendente</span>
          ) : (
            
            <span>{(user.paid !== "5" && user.paid !== "3") ? user.paid_status : <></>}</span>
          )}
        </div>
      </Link>
    </Container>
  );
}

export default memo(StatusBar);
