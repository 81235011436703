/* eslint-disable array-callback-return */
import React from "react";
import PropTypes from "prop-types";
import { colors } from "./../../styles/colors";
import { getTipoPapel } from "../../utils/getMessage";
import emailIcon from "../../assets/images/icons/email.png";
import Logo from "../../assets/images/SAFYSIGN-LOGO-PNG.png";
import Humanist777 from "../../assets/fonts/Humanist777.ttf";
import Handlee from "../../assets/fonts/Handlee-Regular.ttf";
import eyeIcon from "../../assets/images/icons/eye-filled.png";
import Brasao from "../../assets/images/SELO_SAFYSIGN-PNG.png";
import Fasthand from "../../assets/fonts/Fasthand-Regular.ttf";
import GreatVibes from "../../assets/fonts/GreatVibes-Regular.ttf";
import penIcon from "../../assets/images/icons/quill-pen-fill.png";
import docAddIcon from "../../assets/images/icons/document-add.png";
import Caveat from "../../assets/fonts/Caveat-VariableFont_wght.ttf";
import IndieFlower from "../../assets/fonts/IndieFlower-Regular.ttf";
import Humanist777Bold from "../../assets/fonts/Humanist777-Bold.ttf";
import dancingScript from "../../assets/fonts/DancingScript-Medium.ttf";
import cedarvilleCursive from "../../assets/fonts/CedarvilleCursive-Regular.ttf";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Link,
  Font,
} from "@react-pdf/renderer";

Font.register({
  family: "Humanist-777",
  fonts: [{ src: Humanist777 }, { src: Humanist777Bold, fontWeight: 900 }],
});

Font.register({
  family: "Great Vibes",
  fonts: [{ src: GreatVibes, fontWeight: 900 }],
});

Font.register({
  family: "Indie Flower",
  fonts: [{ src: IndieFlower, fontWeight: 900 }],
});

Font.register({
  family: "Cedarville Cursive",
  fonts: [{ src: cedarvilleCursive, fontWeight: 900 }],
});

Font.register({
  family: "Caveat",
  fonts: [{ src: Caveat, fontWeight: 900 }],
});

Font.register({
  family: "Dancing Script",
  fonts: [{ src: dancingScript, fontWeight: 900 }],
});

Font.register({
  family: "Fasthand",
  fonts: [{ src: Fasthand, fontWeight: 900 }],
});

Font.register({
  family: "Handlee",
  fonts: [{ src: Handlee, fontWeight: 900 }],
});

Font.register(
    {
        family: 'Handlee',
        fonts: [{ src: Handlee, fontWeight: 900 }]
    }
)

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#fff",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
  },

  header: {
    width: "210mm",
    height: "20.81mm",
    borderBottom: "0.53mm solid #60159a",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    position: "absolute",
    top: "0",
    padding: "0 30.8mm",
    marginBottom: "7.06mm",
  },

  pageContent: {
    width: "168mm",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
  },

  titulo: {
    color: `${colors.primary}`,
    width: "100%",
    textAlign: "center",
    fontSize: "4.5mm",
    fontWeight: "heavy",
    fontFamily: "Humanist-777",
    marginTop: "10px",
    textTransform: "uppercase",
  },

  assinaturasContainer: {
    maxWidth: "100%",
    display: "flex",
    flexWrap: "wrap",
    margin: "5mm 0",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    color: "#5d5d5d",
  },

  assinaturaContainer: {
    flex: "1 1 70mm",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: "15mm",
  },

  assinaturaText: {
    fontSize: "11pt",
    color: `${colors.darker}`,
    lineHeight: "0.5mm",
    width: "70mm",
    textAlign: "center",
    fontFamily: "Humanist-777",
  },

  processoInfo: {
    width: "100%",
  },

  defaultText: {
    color: `${colors.gray600}`,
    fontSize: "10pt",
    fontFamily: "Humanist-777",
    fontWeight: "normal",
    paddingTop: "2mm",
    paddingBottom: "0mm",
    // wordBreak: "break-all",
  },

  defaultText2: {
    color: `${colors.gray600}`,
    fontSize: "10pt",
    fontFamily: "Humanist-777",
    paddingTop: "2mm",
    fontWeight: "normal",
    paddingBottom: "0mm",
  },

  strongText: {
    color: `${colors.gray600}`,
    fontSize: "10pt",
    fontFamily: "Humanist-777",
    fontWeight: "heavy",
    paddingTop: "2mm",
    paddingBottom: "0mm",
    wordBreak: "break-all",
  },

  strongText2: {
    color: `${colors.gray600}`,
    fontSize: "10pt",
    fontFamily: "Humanist-777",
    fontWeight: "heavy",
    paddingTop: "2mm",
    paddingBottom: "0mm",
  },

  hashCode: {
    color: "#333",
    fontSize: "9t",
    fontFamily: "Humanist-777",
    fontWeight: "heavy",
    paddingTop: "2mm",
    paddingBottom: "2mm",
    wordBreak: "break-all",
  },

  hashCode2: {
    color: `${colors.gray600}`,
    fontSize: "9pt",
    fontFamily: "Humanist-777",
    fontWeight: "normal",
    paddingTop: "2mm",
    paddingBottom: "2mm",
    wordBreak: "break-all",
  },

  docInfoContainer: {
    width: "100%",
    paddingBottom: "5px",
    paddingTop: "5px",
  },

  sectionTitle: {
    fontSize: "4.5mm",
    width: "100%",
    color: "#60159a",
    textAlign: "center",
    marginBottom: "3mm",
    padding: "3.23mm",
    backgroundColor: "rgb(239, 239, 239)",
    borderRadius: "1.32mm",
    textTransform: "uppercase",
    fontFamily: "Humanist-777",
    fontWeight: "heavy",
  },

  docInfo: {
    backgroundColor: "#f9f9f9",
    padding: "3mm 7mm 5mm 7mm",
    marginBottom: "3mm",
    borderRadius: "1.32mm",
    color: `${colors.gray600}`,
  },

  signatariosContainer: {
    width: "100%",
    paddingBottom: "5px",
    paddingTop: "5px",
  },

  signatarioInfo: {
    backgroundColor: "#f9f9f9",
    padding: "3mm 7mm 5mm 7mm",
    marginBottom: "3mm",
    borderRadius: "1.32mm",
    color: `${colors.gray600}`,
  },

  eventoContainer: {
    width: "100%",
    backgroundColor: "#f9f9f9",
    marginBottom: "3mm",
    padding: "3mm 4mm 4mm 7mm",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderRadius: "1.32mm",
    gap: "8mm",
  },

  eventoData: {
    display: "flex",
    alignItems: "center",
    width: "18mm",
  },

  iconContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "14mm",
  },

  footer: {
    width: "210mm",
    borderTop: "0.53mm solid #60159a",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: "5mm",
    position: "absolute",
    bottom: "0",
    padding: "2mm",
  },

  pageNumber: {
    color: "grey",
    fontSize: "11pt",
    marginBottom: "4mm",
    fontFamily: "Humanist-777",
  },
});

CertificadoPDF.propTypes = {
  certificadoData: PropTypes.array,
  historicoData: PropTypes.array,
  isLoading: PropTypes.bool,
};

export default function CertificadoPDF({
  certificateData,
  eventData,
  processData,
}) {
  function getEventIcon(eventType) {
    const code = Number(eventType);

    switch (code) {
      case 1:
        return docAddIcon;
      case 2:
        return emailIcon;
      case 3:
        return eyeIcon;
      case 4:
        return penIcon;
      default:
        return "";
    }
  }

  function getEventMessage(eventType, createdBy, latitude, longitude, ip) {
    const code = Number(eventType);

    switch (code) {
      case 1:
        return (
          <>
            <Text style={styles.defaultText}>
              {createdBy} Criou o processo.
            </Text>
          </>
        );
      case 2:
        return (
          <>
            <Text style={styles.defaultText}>
              Email enviado para os signatarios.
            </Text>
          </>
        );
      case 3:
        return (
          <>
            <Text style={styles.defaultText}>
              {createdBy} Acessou o processo
            </Text>
            <Text
              style={{ ...styles.defaultText, wordBreak: "break-all" }}
            >{`Latitude: ${
              latitude !== null &&
              latitude !== undefined &&
              latitude !== "" &&
              latitude !== "undefined"
                ? latitude
                : "Não informado"
            }, Longitude: ${
              longitude !== null &&
              longitude !== undefined &&
              longitude !== "" &&
              longitude !== "undefined"
                ? longitude
                : "Não informado"
            }`}</Text>
            <Text
              style={{ ...styles.defaultText, wordBreak: "break-all" }}
            >{`Ip: ${ip}`}</Text>
          </>
        );
      case 4:
        return (
          <>
            <Text style={styles.defaultText}>
              {createdBy} Assinou o documento
            </Text>
            <Text style={{ ...styles.defaultText, wordBreak: "break-all" }}>
              Latitude:{" "}
              {latitude !== null &&
              latitude !== undefined &&
              latitude !== "" &&
              latitude !== "undefined"
                ? latitude
                : "Não informado"}
              , Longitude:{" "}
              {longitude !== null &&
              longitude !== undefined &&
              longitude !== "" &&
              longitude !== "undefined"
                ? longitude
                : "Não informado"}
            </Text>
            <Text
              style={{ ...styles.defaultText, wordBreak: "break-all" }}
            >{`Ip: ${ip}`}</Text>
          </>
        );
      case 5:
        return "Cancelou o processo.";
      case 6:
        return "Excluiu o processo.";
      default:
        return "";
    }
  }

  function dateFormat(date, mode = 1) {
    if (date != null) {
      if (mode === 1) {
        let splicedArray = date.split("T")[0].split("-");
        let arrDate = date.split("T");

        return (
          splicedArray[2] +
          "/" +
          splicedArray[1] +
          "/" +
          splicedArray[0] +
          " " +
          arrDate[1].split(".")[0]
        );
      } else {
        let splicedArray = date.split("-");
        return splicedArray[2] + "/" + splicedArray[1] + "/" + splicedArray[0];
      }
    } else {
      return ""; // Trate o caso onde date é null ou undefined, retornando um valor padrão ou vazio, dependendo do seu caso de uso.
    }
  }

  function formataCPF(cpf) {
    cpf = cpf.replace(/[^\d]/g, "");

    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  }

  function getFontByType(type) {
    switch (type) {
      case "1":
        return "Caveat";
      case "2":
        return "Indie Flower";
      case "3":
        return "Cedarville Cursive";
      case "4":
        return "Fasthand";
      case "5":
        return "Arizonia";
      case "6":
        return "Dancing Script";
      case "7":
        return "Great Vibes";
      case "8":
        return "Handlee";
      default:
        return "Cedarville Cursive";
    }
  }

  return (
    <Document style={{ display: "flex" }}>
      <Page
        style={{
          paddingTop: "30mm",
          paddingBottom: "20mm",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <View style={styles.header} fixed>
          <Image src={Logo} style={{ width: "35mm" }} alt="SafySign" />
          <Image src={Brasao} style={{ width: "20mm" }} alt="Certificado" />
        </View>
        <View style={styles.pageContent}>
          <Text style={styles.titulo}>Certificado de Conclusão</Text>
          <View style={styles.assinaturasContainer}>
            <View style={styles.processoInfo}>
              <Text style={styles.strongText2}>
                Nome do processo:{" "}
                <Text style={styles.defaultText2}>{processData?.name}</Text>
              </Text>
              <Text style={styles.strongText2}>
                Identificação do processo:{" "}
                <Text style={styles.defaultText2}>{certificateData?.id}</Text>
              </Text>
              <Text style={styles.strongText2}>
                Quantidade de documentos:{" "}
                <Text style={styles.defaultText2}>
                  {processData?.documents?.length}
                </Text>
              </Text>
              <Text style={styles.strongText2}>
                Acessar o certificado na web{" "}
                <Link
                  style={{
                    ...styles.defaultText2,
                    color: colors.primary,
                    textDecoration: "none",
                  }}
                  src={`app.safysign.com.br/certificate/${certificateData?.id}/${certificateData?.hash}/`}
                >
                  Acessar certificado!
                </Link>
              </Text>
              <Text style={styles.strongText2}>
                Criado por:{" "}
                <Text style={styles.defaultText2}>
                  {processData?.created_by?.name} (
                  {processData?.created_by?.email})
                </Text>
              </Text>
              {processData?.company === null ? (
                ""
              ) : (
                <Text style={styles.strongText2}>
                  Instituição:{" "}
                  <Text style={styles.defaultText2}>
                    {processData?.company_info === null
                      ? ""
                      : processData?.company_info.social_reason}
                  </Text>
                </Text>
              )}
              {processData?.company === null ? (
                ""
              ) : (
                <Text style={styles.strongText2}>
                  CNPJ:{" "}
                  <Text style={styles.defaultText2}>
                    {processData?.company_info === null
                      ? ""
                      : processData?.company_info.cnpj}
                  </Text>
                </Text>
              )}
              <Text style={styles.strongText2}>
                Data de Criação:{" "}
                <Text style={styles.defaultText2}>
                  {dateFormat(processData?.created_at)}
                </Text>
              </Text>
              <Text style={styles.strongText2}>
                Data de Finalização:{" "}
                <Text style={styles.defaultText2}>
                  {dateFormat(processData?.updated_at)}
                </Text>
              </Text>
              <Text style={styles.strongText2}>
                Hash256:{" "}
                <Text style={styles.defaultText2}>
                  {processData?.documents[0].hash}
                </Text>
              </Text>
              <View style={{ display: "flex", flexDirection: "column" }}>
                <Text style={{ ...styles.sectionTitle, marginTop: "15px" }}>
                  Assinaturas
                </Text>
                {certificateData?.signatures.map((signature) => (
                  <View 
                    wrap={false}
                    key={`${signature.id}1`}
                    style={{
                      border: `1px dashed #000000`,
                      borderRadius: "1.32mm",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-evenly",
                      padding: "10px 0",
                      marginBottom: "10px",
                      gap: "2rem",
                    }}
                  >
                    {signature.signature.constructor().toString().length ===
                    15 ? (
                      <View
                        style={{
                          width: "100mm",
                          marginLeft: "3%",
                          alignItems: "center",
                          justifyContent: "center",
                          borderBottom: `2px solid ${colors.darker}`,
                        }}
                      >
                        <Text
                          style={{
                            alignItems: "center",
                            justifyContent: "center",
                            color: `${colors.darker}`,
                            fontSize: "7mm",
                            fontFamily: getFontByType(
                              signature?.signature?.font
                            ),
                          }}
                          fontFamily={getFontByType(signature?.signature?.font)}
                        >
                          {signature?.signature?.text}
                        </Text>
                      </View>
                    ) : (
                      <View
                        style={{
                          width: "100mm",
                          // marginBottom: "13px",
                          display:'flex',
                          alignItems: "center",
                          justifyContent: "center",
                          borderBottom: `2px solid ${colors.darker}`,
                          marginLeft: "3%",
                        }}
                      >
                        <Image
                          src={signature?.signature}
                          style={{ width: "25mm" }}
                          alt="Imagem da Assinatura"
                          allowDangerousPaths={true}
                        />
                      </View>
                    )}
                    <View
                      style={{
                        width: "100mm",
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                      }}
                    >
                      <Text
                        style={{ ...styles.strongText2, textAlign: "center" }}
                      >
                        Tipo:{" "}
                        <Text style={styles.defaultText2}>
                          {processData?.signatories.map((item) => {
                            if (item?.email === signature?.email_signature) {
                              return getTipoPapel(parseInt(item?.type));
                            }
                          })}
                        </Text>
                      </Text>
                      <Text
                        style={{ ...styles.strongText2, textAlign: "center" }}
                      >
                        CPF:{" "}
                        <Text style={styles.defaultText2}>
                          {signature?.cpf}
                        </Text>
                      </Text>
                      <Text
                        style={{ ...styles.strongText2, textAlign: "center" }}
                      >
                        Nome:{" "}
                        <Text style={styles.defaultText2}>
                          {signature?.name}
                        </Text>
                      </Text>
                    </View>
                  </View>
                ))}
              </View>
            </View>
          </View>
          <View style={styles.docInfoContainer} break>
            <Text style={styles.sectionTitle}>Documentos</Text>
            {processData.documents.map((document) => (
              <View wrap={false}
                style={{
                  ...styles.docInfo,
                  wordBreak: "break-all",
                  display: "flex",
                  flexWrap: "wrap",
                }}
                key={`${document?.id}2`}
              >
                <Text style={styles.strongText}>
                  Documento:{" "}
                  <Text style={styles.defaultText}>{document?.name}</Text>
                </Text>
                <Text style={styles.strongText}>
                  Identificador:{" "}
                  <Text style={styles.defaultText}>{document?.id}</Text>
                </Text>
                <Text style={styles.strongText}>
                  Data de envio ao sistema:{" "}
                  <Text style={styles.defaultText}>
                    {dateFormat(document?.created_at)}
                  </Text>
                </Text>
                <Text style={styles.strongText}>
                  Hash SHA256: 
                  <Text style={styles.hashCode2}> {document?.hash}</Text>
                </Text>
                <Text style={styles.strongText}>Hash SHA512: 
                  <Text style={{ ...styles.hashCode2, paddingBottom: "0", lineHeight: '.5mm' }}> { certificateData?.hash?.substring(0, 70)}
                  </Text>
                  <Text style={styles.hashCode2}>
                     {certificateData?.hash?.substring(70, 128)}
                  </Text>
                </Text>
                {/* </View> */}
              </View>
            ))}
          </View>
          <View style={styles.signatariosContainer} >
            <Text style={styles.sectionTitle}>Signatários</Text>
            {processData?.signatories?.map((signatory) => (
              <View
                key={`${signatory.id}3`}
                style={styles.signatarioInfo}
                wrap={false}
              >
                <Text style={{ ...styles.strongText, fontSize: "12pt" }}>
                  {certificateData?.signatures?.map((item) => {
                    if (item?.email_signature === signatory?.email) {
                      return item?.name;
                    }
                  })}{" "}
                  ({getTipoPapel(signatory?.type)})
                </Text>
                <Text style={styles.strongText}>
                  CPF:{" "}
                  <Text style={styles.defaultText}>
                    {certificateData?.signatures?.map((item) => {
                      if (item?.email_signature === signatory?.email) {
                        return formataCPF(item?.cpf);
                      }
                    })}
                  </Text>
                </Text>
                <Text style={styles.strongText}>
                  Data de nascimento:{" "}
                  <Text style={styles.defaultText}>
                    {certificateData?.signatures?.map((item) => {
                      if (item?.email_signature === signatory?.email) {
                        return dateFormat(item?.age, 2).includes("undefined/")
                          ? dateFormat(item?.age, 2).replaceAll(
                              "undefined/",
                              ""
                            )
                          : dateFormat(item?.age, 2);
                      }
                    })}
                  </Text>
                </Text>
                <Text style={{...styles.defaultText, lineHeight: '.5mm'}}>
                  Assinou o documento em{" "}
                  {
                    <Text style={styles.strongText}>
                      {certificateData?.signatures?.map((item) => {
                        if (item?.email_signature === signatory?.email) {
                          return dateFormat(item?.created_at);
                        }
                      })}
                    </Text>
                  }{" "}
                  com o IP:
                  {
                    <Text style={styles.strongText}>
                      {certificateData?.signatures?.map((item) => {
                        if (item?.email_signature === signatory?.email) {
                          return item?.ip;
                        }
                      })}
                    </Text>
                  }{" "}
                  na localização latitude:{" "}
                  {
                    <Text style={styles.strongText}>
                      {certificateData?.signatures?.map((item) => {
                        if (item?.email_signature === signatory?.email) {
                          return item?.latitude;
                        }
                      })}
                    </Text>
                  }
                  {", Logitude:"}
                  {
                    <Text style={styles.strongText}>
                      {certificateData?.signatures?.map((item) => {
                        if (item?.email_signature === signatory?.email) {
                          return item?.longitude;
                        }
                      })}
                    </Text>
                  }
                </Text>
              </View>
            ))}
          </View>
          <View style={styles.signatariosContainer} >
            <Text style={{ ...styles.sectionTitle, textAlign: "center" }}>
              Histórico
            </Text>
            {eventData?.map((event) => {
              const eventIcon = getEventIcon(event?.event);
              const eventoData = dateFormat(event?.created_at).split(" ");
              return (
                <View
                  style={styles.eventoContainer}
                  key={`${event.id}4`}
                  wrap={false}
                >
                  <View style={styles.eventoData}>
                    <Text style={styles.defaultText}>{eventoData[0]}</Text>
                    <Text style={styles.defaultText}>{eventoData[1]}</Text>
                  </View>
                  <View style={styles.iconContainer}>
                    <Image src={eventIcon} style={{ width: "8mm" }} />
                  </View>
                  <View>
                    {getEventMessage(
                      event?.event,
                      event?.created_by,
                      event?.latitude,
                      event?.longitude,
                      event?.ip
                    )}
                  </View>
                </View>
              );
            })}
          </View>
        </View>
        <View style={styles.footer} fixed>
          <Text
            style={styles.pageNumber}
            render={({ pageNumber, totalPages }) =>
              `${pageNumber} / ${totalPages}`
            }
            fixed
          />
          <Link
            src="www.safysign.com.br"
            style={{
              textDecoration: "none",
              color: "#60159a",
              fontSize: "10.5pt",
              fontFamily: "Humanist-777",
            }}
          >
            www.safysign.com.br
          </Link>
          <Text
            style={{
              fontSize: "10.5pt",
              color: "#333",
              marginTop: "2mm",
              fontFamily: "Humanist-777",
            }}
          >
            Todos os direitos reservados a marca SafySign.
          </Text>
        </View>
      </Page>
    </Document>
  );
}
