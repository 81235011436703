import {toast} from "react-toastify";
import {Link} from "react-router-dom";
import Modal from "@mui/material/Modal";
import {FiCheck} from "react-icons/fi";
import {Collapse} from "react-collapse";
import * as api from "../../services/api";
import {MdDelete} from "react-icons/md";
import {HiPencil} from "react-icons/hi";
import Input from "../../components/Input";
import {Pagination} from "@mui/material";
import {RadioGroup, Radio} from "rsuite";
import {FadeLoader} from "react-spinners";
import {colors} from "./../../styles/colors";
import {IoMdArrowDropup} from "react-icons/io";
import useWindowSize2 from "../../hooks/useWindowSize2";
import React, {useState, useEffect, memo} from "react";
import SafeButton from "./../../components/SafeButton/index";
import {currencyFormatter, dateFormat} from "../../utils/INTL";
import {
    Container,
    InputContainer,
    Form,
    ControlsContainer,
    CollapseContent,
    TableContent,
    ContainerNewSafy,
    CardContent,
    RadioContainer,
} from "./styles";

const Test = memo(({plano}) => {
    let header = [
        {label: 'Valor', value: currencyFormatter(plano?.price)},
        {label: 'Qtd de Usuários', value: plano?.infinite_users ? "Usuários Ilimitados" : plano?.users},
        {label: 'Qtd de Documentos', value: plano?.infinite_documents ? "Documentos Ilimitados" : plano?.processes},
        {label: 'Tipo', value: plano?.plan_type === "1" ? "Mensal" : "Anual"},
        {label: 'Visibilidade', value: plano?.visibility === "1" ? "Todos" : "Cliente"},
        {label: 'Data de Criação', value: dateFormat(plano.created_at)}
    ]

    return (
        header.map(item => {
            return (
                <CollapseContent key={plano?.id}>
                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <div>
                            <h5>{item?.label}</h5>
                            <p>{item?.value}</p>
                        </div>
                        <HiPencil size={20} color={colors.primary}/>
                    </div>
                </CollapseContent>
            )
        })
    )
})

export default function Plans() {
    const [page, setPage] = useState();
    const [plans, setPlans] = useState([]);
    const [open, setOpen] = useState(false);
    const [, setModalValor] = useState(false);
    const [response, setResponse] = useState();
    const [collapse, setCollapse] = useState("");
    const [planType, setPlanType] = useState("1");
    const [category, setCategory] = useState("1");
    const [isLoading, setIsLoading] = useState(true);
    const [visibility, setVisibility] = useState("1");
    const [modalContent, setModalContent] = useState({});
    const [confirmation, setConfirmation] = useState(false);

    const width = useWindowSize2();

    const handleOpen = (plan) => {
        setOpen(true);
        setModalValor(true);
        setModalContent(plan);
    };

    function getPlans(page = 1) {
        api
            .get(`/plan/list/?page=${page}`)
            .then((response) => {
                if (response?.status === 200) {
                    setIsLoading(false);
                    setResponse(response?.data.detail.count);
                    setPlans(response?.data.detail.results);
                } else {
                    toast.error("Ocorreu um erro ao resgatar a listagem de planos", {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            })
            .catch((error) => {
                toast.error("Ocorreu um erro ao resgatar a listagem de planos", {
                    position: toast.POSITION.TOP_RIGHT,
                });
            });
    }

    useEffect(() => {
        getPlans();
    }, []);

    async function submit(evento) {
        const formData = new FormData(evento);
        let formProps = Object.fromEntries(formData);
        let data = {
            ...formProps,
            category: category,
            visibility: visibility,
            plan_type: planType,
        };

        api
            .put(`/plan/update/${modalContent.id}/`, data)
            .then((response) => {
                if (response?.status === 200) {
                    setOpen(false);
                    getPlans(page);
                    toast.success("Plano alterado com sucesso", {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                } else {
                    toast.error("Ocorreu um erro ao tentar atualizar o plano", {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            })
            .catch((error) => {
                toast.error("Ocorreu um erro ao tentar atualizar o plano", {
                    position: toast.POSITION.TOP_RIGHT,
                });
            });
    }

    async function deletePlan(id) {
        api
            .destroy(`/plan/destroy/${id}/`)
            .then((response) => {
                if (response?.status === 204) {
                    toast.success("O plano foi deletado com sucesso", {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    setOpen(false);
                    getPlans();
                } else {
                    toast.error("Ocorreu um erro ao deletar o plano", {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            })
            .catch((error) => {
                toast.error("Ocorreu um erro ao deletar o plano", {
                    position: toast.POSITION.TOP_RIGHT,
                });
            });
    }

    return (
        <>
            <ControlsContainer>
                <Link to="/plans/create">
                    <SafeButton variant="primary">Criar Novo Plano</SafeButton>
                </Link>
            </ControlsContainer>
            {width[0] > 700 && (
                <>
                    <Container>
                        <TableContent>
                            <table>
                                <thead>
                                <tr>
                                    <th>Nome do Plano</th>
                                    <th>Valor</th>
                                    <th>Qtd de Usuários</th>
                                    <th>Qtd de Documentos</th>
                                    <th>Tipo</th>
                                    <th>Abrangência</th>
                                    <th>Data de Criação</th>
                                </tr>
                                </thead>
                                <tbody>
                                {isLoading ? (
                                    <tr>
                                        <td colSpan={7}>
                                            <div className="content-loader">
                                                <FadeLoader color={colors.primary}/>
                                            </div>
                                        </td>
                                    </tr>
                                ) : (
                                    plans.map((plan, index) => {
                                        return (
                                            <tr
                                                key={plan.id}
                                                onClick={() => {
                                                    setCategory(plan?.category);
                                                    setPlanType(plan?.plan_type);
                                                    setVisibility(plan?.visibility);
                                                    handleOpen(plan);
                                                }}
                                            >
                                                <td dataname="Nome:">{plan.name}</td>
                                                <td dataname="Valor:">
                                                    {currencyFormatter(plan.price)}
                                                </td>
                                                <td dataname="Usuários:">{plan.users}</td>
                                                <td dataname="Documentos:">{plan.processes}</td>
                                                <td dataname="Tipo:">
                                                    {plan.plan_type === "1" ? "Mensal" : "Anual"}
                                                </td>
                                                <td dataname="Abrangência:">
                                                    {plan.category === "1" ? "Pessoal" : "Empresarial"}
                                                </td>
                                                <td dataname="Data Criação:">
                                                    {dateFormat(plan?.created_at)}
                                                </td>
                                            </tr>
                                        );
                                    })
                                )}
                                </tbody>
                            </table>
                        </TableContent>
                    </Container>
                    <div style={{marginTop: "10px"}} className="pageCount">
                        <Pagination
                            count={Math.ceil(response / 10)}
                            variant="outlined"
                            shape="rounded"
                            onChange={(e, value) => {
                                getPlans(value);
                                setPage(value);
                                setIsLoading(true);
                            }}
                        />
                    </div>
                </>
            )}

            {width[0] < 700 && (
                <>
                    {isLoading ? (
                        <div className="content-loader">
                            <FadeLoader color={colors.primary}/>
                        </div>
                    ) : (
                        plans.map((plano, index) => {
                            return (
                                <CardContent>
                                    <CollapseContent
                                        onClick={() =>
                                            setCollapse((collapse) =>
                                                collapse === plano?.id ? "" : plano?.id
                                            )
                                        }
                                    >
                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: "center"
                                        }}>
                                            <div>
                                                <h5>Nome do plano</h5>
                                                <p>{plano?.name}</p>
                                            </div>
                                            <IoMdArrowDropup size={20} color={colors.primary}
                                                             style={{transform: `rotate(${collapse === plano.id ? 0 : 180}deg)`}}/>
                                        </div>
                                    </CollapseContent>
                                    <Collapse isOpened={plano?.id === collapse}>
                                        <div
                                            onClick={() => {
                                                handleOpen(plano);
                                            }}
                                        >
                                            <Test plano={plano}/>
                                        </div>
                                    </Collapse>
                                </CardContent>
                            );
                        })
                    )}
                    <div style={{marginTop: "10px"}} className="pageCount">
                        <Pagination
                            count={Math.ceil(response / 10)}
                            variant="outlined"
                            shape="rounded"
                            onChange={(e, value) => {
                                getPlans(value);
                                setPage(value);
                                setIsLoading(true);
                            }}
                        />
                    </div>
                </>
            )}

            <Modal
                open={open}
                onClose={() => {
                    setOpen(false);
                    setCategory("1");
                    setVisibility("1");
                    setPlanType("1");
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <>
                    <ContainerNewSafy>
                        <Form
                            onSubmit={(e) => {
                                e.preventDefault();
                                submit(e.target);
                            }}
                            action="reset"
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "stretch",
                                justifyContent: "space-around",
                            }}
                        >
                            <InputContainer>
                                <p style={{textAlign: "center", fontWeight: "700"}}>Nome:</p>
                                <Input
                                    type="text"
                                    defaultValue={modalContent?.name}
                                    name="name"
                                    placeholder="Nome do Plano"
                                    id="nome_plano"
                                />
                            </InputContainer>
                            <InputContainer>
                                <p style={{textAlign: "center", fontWeight: "700"}}>
                                    Valor: R$
                                </p>
                                <input
                                    type="number"
                                    defaultValue={modalContent?.price}
                                    name="price"
                                    step=".01"
                                    placeholder="0.00"
                                />
                            </InputContainer>
                            <InputContainer>
                                <p style={{textAlign: "center", fontWeight: "700"}}>
                                    Quantidade de Usuários:{" "}
                                </p>
                                <Input
                                    type="number"
                                    defaultValue={modalContent?.users}
                                    name="users"
                                    placeholder="0"
                                />
                            </InputContainer>
                            <InputContainer>
                                <p style={{textAlign: "center", fontWeight: "700"}}>
                                    Quantidade de Documentos:{" "}
                                </p>
                                <Input
                                    type="number"
                                    name="processes"
                                    defaultValue={modalContent?.processes}
                                    placeholder="0"
                                />
                            </InputContainer>
                            <InputContainer>
                                <p style={{textAlign: "center", fontWeight: "700"}}>
                                    Email:{" "}
                                </p>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: 'row',
                                        justifyContent: "space-between"
                                    }}
                                >
                                    <input min={0} max={99} name="email_amount" placeholder="Dias" type="number"
                                           style={{textAlign: 'center', width: '48%'}} required
                                           defaultValue={modalContent?.email_amount}/>
                                    <input min={0} max={99} name="email_frequency" placeholder="Frequência"
                                           type="number" style={{textAlign: 'center', width: '48%'}} required
                                           defaultValue={modalContent?.email_frequency}/>
                                </div>
                            </InputContainer>
                            <InputContainer>
                                <p style={{textAlign: "center", fontWeight: "700"}}>
                                    SMS:{" "}
                                </p>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: 'row',
                                        justifyContent: "space-between"
                                    }}
                                >
                                    <input min={0} max={99} name="sms_amount" placeholder="Dias" type="number"
                                           style={{textAlign: 'center', width: '48%'}} required
                                           defaultValue={modalContent?.sms_amount}/>
                                    <input min={0} max={99} name="sms_frequency" placeholder="Frequência" type="number"
                                           style={{textAlign: 'center', width: '48%'}} required
                                           defaultValue={modalContent?.sms_frequency}/>
                                </div>
                            </InputContainer>
                            <InputContainer>
                                <p style={{textAlign: "center", fontWeight: "700"}}>
                                    WhatsApp:{" "}
                                </p>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: 'row',
                                        justifyContent: "space-between"
                                    }}
                                >
                                    <input min={0} max={99} name="whatsapp_amount" placeholder="Dias" type="number"
                                           style={{textAlign: 'center', width: '48%'}} required
                                           defaultValue={modalContent?.whatsapp_amount}/>
                                    <input min={0} max={99} name="whatsapp_frequency" placeholder="Frequência"
                                           type="number" style={{textAlign: 'center', width: '48%'}} required
                                           defaultValue={modalContent?.whatsapp_frequency}/>
                                </div>
                            </InputContainer>
                            <RadioContainer style={{paddingTop: "15px"}}>
                                <RadioGroup
                                    value={planType}
                                    defaultValue={planType}
                                    onChange={(value) => setPlanType(value)}
                                >
                                    <p style={{fontWeight: "700"}}>Tipo do Plano:</p>
                                    <Radio value="1">Plano Mensal</Radio>
                                    <Radio value="2">Plano Anual</Radio>
                                </RadioGroup>
                            </RadioContainer>
                            <RadioContainer>
                                <RadioGroup
                                    value={category}
                                    defaultValue={category}
                                    onChange={(value) => setCategory(value)}
                                >
                                    {" "}
                                    <p style={{fontWeight: "700"}}>Categoria:</p>
                                    <Radio value="1">Pessoal</Radio>
                                    <Radio value="2">Empresarial</Radio>
                                </RadioGroup>
                            </RadioContainer>
                            <RadioContainer>
                                <RadioGroup
                                    value={visibility}
                                    defaultValue={visibility}
                                    onChange={(value) => setVisibility(value)}
                                >
                                    <p style={{fontWeight: "700"}}>Abrangência:</p>
                                    <Radio value="1">Público</Radio>
                                    <Radio value="2">Privado</Radio>
                                </RadioGroup>
                            </RadioContainer>
                            <InputContainer>
                                <p style={{textAlign: "center", fontWeight: "700"}}>
                                    Descrição:{" "}
                                </p>
                                <textarea
                                    maxLength={255}
                                    type="text"
                                    id="descricao_planos"
                                    name="description"
                                    placeholder="Descrição"
                                    defaultValue={modalContent?.description}
                                    style={{
                                        width: "100%",
                                        textAlign: "center",
                                        height: "120px",
                                        resize: "none",
                                        background: "#ececec",
                                    }}
                                />
                            </InputContainer>
                            <div className="buttonsContainer">
                                {confirmation ? (
                                    <div className="buttons">
                                        <p>Tem certeza?</p>
                                        <SafeButton
                                            variant="secondary"
                                            type="button"
                                            onClick={() => setConfirmation(false)}
                                        >
                                            Não
                                        </SafeButton>
                                        <SafeButton
                                            variant="primary"
                                            type="button"
                                            onClick={() => {
                                                deletePlan(modalContent?.id);
                                            }}
                                        >
                                            Sim
                                        </SafeButton>
                                    </div>
                                ) : (
                                    <>
                                        <SafeButton
                                            variant="secondary"
                                            type="button"
                                            onClick={() => setConfirmation(true)}
                                        >
                                            <MdDelete color="#60159a" size={20}/>
                                        </SafeButton>
                                        <SafeButton
                                            variant="primary"
                                            className="long-button"
                                            type="submit"
                                        >
                                            <FiCheck color="#fff" size={20}/>
                                        </SafeButton>
                                    </>
                                )}
                            </div>
                        </Form>
                    </ContainerNewSafy>
                </>
            </Modal>
        </>
    );
}
