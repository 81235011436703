import { BarLoader } from 'react-spinners';
import { ImBlocked } from 'react-icons/im';
import { colors } from '../../styles/colors';
import { PrimaryButton, SecondaryButton, TertiaryButton } from './styles';

export default function SafeButton({ 
  variant = 'primary', 
  loading, 
  blocked, 
  blockedMessage, 
  blockedColor, 
  invisible, 
  children, 
  ...rest 
}) {
  const ButtonComponent = variant === 'primary' 
    ? PrimaryButton 
    : variant === 'secondary' 
    ? SecondaryButton 
    : TertiaryButton;

  return (
    <ButtonComponent 
      invisible={invisible} 
      disabled={loading || blocked} 
      {...rest} 
    >
      {
        loading 
          ? <BarLoader height={6} width={80} color={colors.secondary} />
          : blocked 
            ? blockedMessage || <ImBlocked size={20} color={blockedColor || colors.white} />
            : children
      }
    </ButtonComponent>
  );
}
