/* eslint-disable react-hooks/exhaustive-deps */
import { toast } from "react-toastify";
import Modal from "@mui/material/Modal";
import * as api from "../../services/api";
import Input from "../../components/Input";
import { ScaleLoader } from "react-spinners";
import { colors } from "./../../styles/colors";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./../../hooks/useAuth";
import { useDash } from "./../../hooks/useDash";
import { currencyFormatter } from "../../utils/INTL";
import useWindowSize2 from "./../../hooks/useWindowSize2";
import { getFontByType, Fonts } from "../../utils/getFont";
import SafeButton from "./../../components/SafeButton/index";
import { InputMaskNormal } from "../../components/Input/InputMask";
import { SignCanvas, saveSign, cleanSign } from "../../components/SignCanvas";
import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import {
  Container,
  PlanoContainer,
  ModalContainer,
  LabelContainer,
  Font,
  Title,
  Row,
} from "./styles";
import { MdCheck } from "react-icons/md";

export default function Profile() {
  const [loading, setLoading] = useState();
  const [planId, setPlanId] = useState(null);
  const [infoUser, setInfoUser] = useState({});
  const [planDetail, setPlanDetail] = useState();
  const [chosenFont, setChosenFont] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [signature, setSignature] = useState({ rubric: null, sign: null });
  const [passwordData, setPasswordData] = useState({
    senhaAtual: "",
    novaSenha: "",
    confirmNovaSenha: "",
  });
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [showSignModal, setShowSignModal] = useState(false);
  const [passwordModalIsOpen, setPasswordModalIsOpen] = useState(false);
  const [confirmSignFontModal, setConfirmSignFontModal] = useState(false);

  const canvasRef = useRef();
  const navigate = useNavigate();
  const window = useWindowSize2();
  const { user, setUser } = useAuth();
  const { getProcessCount, allProccess } = useDash();

  async function getBalanceAgain() {
    await getProcessCount();
  }

  useLayoutEffect(() => {
    getBalanceAgain().then(() => {
      setIsLoading(false);
    });
    api.get(`/user/retrieve/${user.id}/`).then((response) => {
      setInfoUser(response?.data?.detail);
    });
    if (
      user.company === null ||
      user.company === "" ||
      user.company === undefined
    ) {
      setPlanId(user.plan);
    } else {
      api.get(`/company/retrieve/${user.company}/`).then((response) => {
        if (response?.status !== 200) {
          toast.error("Ocorreu um erro ao resgatar informações da empresa", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          setPlanId(response?.data?.detail.plan);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (planId !== null) {
      api.get(`/plan/retrieve/${planId}/`).then((response) => {
        if (response?.status !== 200) {
          toast.error("Ocorreu um erro ao resgatar informações do plano");
        } else {
          setPlanDetail(response?.data?.detail);
        }
      });
    }
  }, [planId]);

  function dateFormat(date) {
    if (date != null) {
      var arrDate = date.split("-");
      return arrDate[2] + "/" + arrDate[1] + "/" + arrDate[0];
    }
  }

  function closeModal() {
    setModalIsOpen(false);
  }

  function closePasswordModal() {
    setPasswordModalIsOpen(false);
    setPasswordData({
      senhaAtual: "",
      novaSenha: "",
      confirmNovaSenha: "",
    });
  }

  function handlePasswordInput(event) {
    const name = event.target.name;
    const value = event.target.value;

    setPasswordData({ ...passwordData, [name]: value });
  }

//   function getQtdMessage(qtdDocumentos, tipoPlano) {
//     const tipo = tipoPlano === "1" ? "mês" : "ano";
//     return qtdDocumentos + " /" + tipo;
//   }

  function closeSignModal() {
    setShowSignModal(false);
  }

  function validateNullRubric(dataUri) {
    if (dataUri === null) {
      toast.error("Rubrica não pode ser enviada em branco", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    } else {
      return true;
    }
  }

  async function submit(evento, mode) {
    const formData = new FormData(evento);
    let formProps = Object.fromEntries(formData);

    if (mode === 1) {
      formProps.phone = formProps.phone.replace(/[^0-9]/g, "");
      api
        .put(`/user/update/${user.id}/`, formProps)
        .then((response) => {
          if (response?.status === 200) {
            toast.success("Usuário alterado com sucesso", {
              position: toast.POSITION.TOP_RIGHT,
            });
            setInfoUser(response?.data?.detail);
            closeModal();

            let updateUser = user;

            updateUser.first_name = formProps.first_name;
            updateUser.last_name = formProps.last_name;
            updateUser.get_full_name =
              formProps.first_name + " " + formProps.last_name;
            updateUser.age = formProps.age;
            updateUser.phone = formProps.phone;

            setUser(updateUser);
          } else {
            toast.error("Erro ao alterar usuário", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch(() => {
          toast.error("Erro ao alterar usuário", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }

    if (mode === 2) {
      if (formProps.password === formProps.confirmPassword) {
        if (
          formProps.confirmPassword !== "" &&
          formProps.confirmPassword !== null &&
          formProps.confirmPassword !== undefined &&
          formProps.password !== "" &&
          formProps.password !== null &&
          formProps.password !== undefined
        ) {
          if (
            formProps.password.match(
              /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%*()_+^&}{:;?.])(?:([0-9a-zA-Z!@#$%;*(){}_+^&])){6,}$/
            )
          ) {
            api
              .put(`/user/change_password/${user.id}/`, {
                password: formProps.password,
              })
              .then((response) => {
                if (response?.status === 200) {
                  toast.success("Senha atualizada com sucesso", {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                  closePasswordModal();
                } else {
                  toast.error("Ocorreu um erro ao tentar alterar a senha", {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                }
              })
              .catch(() => {
                toast.error("Ocorreu um erro ao tentar alterar a senha", {
                  position: toast.POSITION.TOP_RIGHT,
                });
              });
          } else
            toast.error(
              "A senha deve conter 8 digitos, entre eles: um numero ,uma letra maiuscula, uma letra minuscula e um caracter especial",
              { position: toast.POSITION.TOP_RIGHT }
            );
        } else {
          toast.error("Os campos não podem estar vazios", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } else {
        toast.error("As senhas não coincidem", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }

    if (mode === 3) {
      let forSubmit = { sign: { ...formProps, font: chosenFont } };
      api
        .put(`/user/signature/update/${infoUser?.signature}/`, forSubmit)
        .then((response) => {
          if (response?.status === 200) {
            const data = { ...signature, sign: response?.data.detail };
            setSignature(data);
            setConfirmSignFontModal(false);
            setChosenFont();
            toast.success("Assinatura atualizada com sucesso", {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error("Ocorreu um erro ao atualizar a assinatura", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((error) => {
          toast.error("Ocorreu um erro ao atualizar a assinatura", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  }

  async function sendData() {
    const { dataUri } = saveSign(canvasRef);

    if (validateNullRubric(dataUri)) {
      const data = {
        rubric: {
          name: `${user.get_full_name}Rubric.png`,
          b64: dataUri,
        },
      };

      api
        .put(`/user/signature/update/${infoUser?.signature}/`, data)
        .then((response) => {
          if (response?.status === 200) {
            const data = { ...signature, rubric: response?.data.detail };
            setSignature(data);
            setShowSignModal(false);
            toast.success("Rubrica cadastrada com sucesso", {
              position: toast.POSITION.TOP_RIGHT,
            });
            setLoading(false);
          } else {
            toast.error("Ocorreu um erro ao cadastrar a rubrica", {
              position: toast.POSITION.TOP_RIGHT,
            });
            setLoading(false);
          }
        })
        .catch((error) => {
          toast.error("Ocorreu um erro ao cadastrar a rubrica", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  }

  function handleChangePlan() {
    if (
      (user.company && user.company_is_bank_slip_payer === true) ||
      user.is_bank_slip_payer === true
    ) {
      toast.info(
        "Entre em contato com o comercial para troca de plano ou adquirir mais saldo!",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );

    } else {
        navigate("/plan", { replace: true });

    }
  }



  return (
    <>
      <Title>
        <p>Minha Conta</p>
        <SafeButton
          variant="primary"
          onClick={() => setPasswordModalIsOpen(true)}
        >
          Alterar senha
        </SafeButton>
      </Title>

      {isLoading ? (
        <ScaleLoader
          color={`${colors.primary}`}
          width="10px"
          height="60px"
          margin="2px"
          radius="1px"
        />
      ) : (
        <>
          <Container>
            <h4>
              {infoUser?.get_full_name
                ? infoUser?.get_full_name
                : infoUser?.first_name + " " + infoUser?.last_name}
            </h4>
            <div className={"container-content"}>
              <div>
                <p>
                  <strong>Email:</strong> {infoUser?.email}
                </p>

                <p>
                  <strong>CPF:</strong> {infoUser?.cpf}.
                </p>

                <p>
                  <strong>Data de nascimento:</strong>{" "}
                  {dateFormat(infoUser?.age)}
                </p>

                <p>
                  <strong>Celular:</strong> {infoUser?.phone}
                </p>

                <p>
                  <strong>Saldo:</strong>{" "}
                  {allProccess?.detail?.balance + " processos restantes"}
                </p>

                {signature?.sign !== null ? (
                  <div style={{ marginTop: "8px" }}>
                    <strong>Assinatura por extenso:</strong>
                    <div style={{ textAlign: "start" }}>
                      <p
                        style={{
                          fontFamily: getFontByType(
                            signature?.sign?.sign?.font
                          ),
                          fontSize: "3rem",
                        }}
                      >
                        {signature?.sign?.sign?.text}
                      </p>
                    </div>
                  </div>
                ) : infoUser?.rubric !== null ? (
                  <div style={{ marginTop: "8px" }}>
                    <strong>Assinatura por extenso:</strong>
                    <div style={{ textAlign: "start" }}>
                      <p
                        style={{
                          fontFamily: getFontByType(infoUser?.sign?.font),
                          fontSize: "3rem",
                        }}
                      >
                        {infoUser?.sign?.text}
                      </p>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {signature?.rubric !== null ? (
                  <div style={{ margin: "3px" }}>
                    <strong>Assinatura do tipo Rubrica:</strong>
                    <div style={{ width: "230px" }}>
                      <div
                        style={{
                          padding: "10px",
                          border: "2px dashed rgba(139, 139, 139, 0.675)",
                          marginLeft: "0",
                          borderRadius: "15px",
                        }}
                      >
                        <img
                          style={{
                            borderRadius: "8px",
                            borderBottom: `2px solid ${colors.darker}`,
                          }}
                          src={`${signature?.rubric?.rubric?.file}`}
                          alt="assinatura cadastrada"
                        />
                      </div>
                    </div>
                  </div>
                ) : infoUser?.rubric !== null ? (
                  <>
                    <strong>Assinatura do tipo Rubrica:</strong>
                    <div style={{ width: "230px" }}>
                      <div
                        style={{
                          padding: "10px",
                          border: "2px dashed rgba(139, 139, 139, 0.675)",
                          marginLeft: "0",
                          borderRadius: "15px",
                        }}
                      >
                        <img
                          style={{ borderBottom: `2px solid ${colors.darker}` }}
                          src={`${infoUser?.rubric?.file}`}
                          alt="assinatura cadastrada"
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>

              <div>
                <PlanoContainer>
                  {planDetail ? (
                    <>
                      <div className="title">
                        <h2>{planDetail?.name}</h2>
                      </div>
                      <div>
                        <p>
                            <MdCheck size={20}/>{" "}
                          {planDetail?.users > 9999
                            ? "+9999 usuários simultâneos."
                            : planDetail?.users + " usuários simultâneos."}
                        </p>
                        {planDetail?.whatsapp_amount > 0 ? (
                          <p style={{ fontSize: "1.7rem" }}>
                            <MdCheck size={20} />{" "}
                            {planDetail?.whatsapp_amount +
                              " Envios de WhatsApp."}
                          </p>
                        ) : (
                          ""
                        )}
                        {planDetail?.email_amount > 0 ? (
                          <p style={{ fontSize: "1.7rem" }}>
                            <MdCheck size={20} />{" "}
                            {planDetail?.email_amount + " Envios de email."}
                          </p>
                        ) : (
                          ""
                        )}
                        {planDetail?.sms_amount > 0 ? (
                          <p style={{ fontSize: "1.7rem" }}>
                            <MdCheck size={20} />{" "}
                            {planDetail?.sms_amount + " Envios de SMS."}
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                      <p>
                        <MdCheck size={20} />
                        {planDetail?.processes + " documentos."}
                      </p>
                      <p>{planDetail?.description}</p>
                      <h3>
                        {planDetail?.price === "0"
                          ? "Grátis"
                          : currencyFormatter(planDetail?.price)}
                        {planDetail?.price === "0"
                          ? ""
                          : planDetail?.plan_type === "1"
                          ? "/mês"
                          : "/ano"}
                      </h3>
                    </>
                  ) : (
                    <>
                      {!user?.company || user?.profile === "2" ? (
                        <>
                          <div className="title">
                            <h2>Selecione um plano:</h2>
                          </div>
                          <SafeButton
                            variant="primary"
                            onClick={() => navigate("/plan", { replace: true })}
                            className="button"
                            style={{ margin: '16px', width: '20rem'}}
                          >
                            Adquirir um plano
                          </SafeButton>
                        </>
                      ) : (
                        ""
                      )}
                    </>
                  )}
                </PlanoContainer>
              </div>
            </div>
            <div
              className={"container-content"}
              style={
                !user?.company || user?.profile === ("2" || "3")
                  ? {}
                  : { width: "100%", flexDirection: "column", gap: "0" }
              }
            >
              <div
                style={
                  !user?.company || user?.profile === ("2" || "3")
                    ? {}
                    : { width: "100%" }
                }
              >
                <SafeButton
                  variant="primary"
                  className="button"
                  onClick={() => {
                    setShowSignModal(true);
                  }}
                >
                  {infoUser?.rubric !== null
                    ? "Alterar Rubrica"
                    : "Cadastrar rubrica"}
                </SafeButton>

                <SafeButton
                  variant="primary"
                  className="button"
                  onClick={() => {
                    setConfirmSignFontModal(true);
                  }}
                >
                  {infoUser?.sign !== null && window[0] > 424
                    ? "Alterar assinatura por extenso"
                    : window[0] < 425 && infoUser?.sign !== null
                    ? "Alterar"
                    : window[0] < 425 && infoUser?.sign === null
                    ? "Cadastrar"
                    : "Cadastrar assinatura por extenso"}
                </SafeButton>
              </div>
              <div
                style={
                  !user?.company || user?.profile === "2"
                    ? {}
                    : { width: "100%" }
                }
              >
                {!user?.company || user?.profile === "2" ? (
                  <SafeButton
                    variant="primary"
                    onClick={handleChangePlan}
                    className="button"
                  >
                    Alterar plano
                  </SafeButton>
                ) : (
                  ""
                )}
                <SafeButton
                  variant="primary"
                  className="button"
                  onClick={() => {
                    setModalIsOpen(!modalIsOpen);
                  }}
                >
                  Alterar cadastro
                </SafeButton>
              </div>
            </div>
          </Container>
          <Modal
            open={modalIsOpen}
            onClose={() => {
              closeModal();
            }}
          >
            <ModalContainer>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  submit(e.target, 1);
                }}
              >
                <Row>
                  <LabelContainer>
                    <label htmlFor="nome">Nome:</label>
                    <Input
                      id="nome"
                      name="first_name"
                      defaultValue={infoUser?.first_name}
                    />
                  </LabelContainer>
                  <LabelContainer
                    style={
                      window[0] > 587
                        ? { width: "45%" }
                        : { width: "100%", maxWidth: "258px" }
                    }
                  >
                    <label htmlFor="sobrenome">Sobrenome:</label>
                    <Input
                      id="sobrenome"
                      name="last_name"
                      defaultValue={infoUser?.last_name}
                    />
                  </LabelContainer>
                </Row>
                <Row>
                  <LabelContainer>
                    <label htmlFor="data_nascimento">Data de nascimento:</label>
                    <Input
                      type="date"
                      maskChar=""
                      id="age"
                      name="age"
                      defaultValue={infoUser?.age}
                    />
                  </LabelContainer>
                  <LabelContainer
                    style={
                      window[0] > 587
                        ? { width: "45%" }
                        : { width: "100%", maxWidth: "258px" }
                    }
                  >
                    <label htmlFor="celular">Celular:</label>
                    <InputMaskNormal
                      mask="(99) 99999-9999"
                      maskChar=""
                      id="phone"
                      name="phone"
                      defaultValue={infoUser?.phone}
                    />
                  </LabelContainer>
                </Row>
                <div className="buttonsContainer">
                  <SafeButton
                    variant="secondary"
                    type="button"
                    onClick={closeModal}
                  >
                    Cancelar
                  </SafeButton>
                  <SafeButton
                    variant="primary"
                    type="submit"
                    loading={isLoading}
                  >
                    Confirmar
                  </SafeButton>
                </div>
              </form>
            </ModalContainer>
          </Modal>

          <Modal open={passwordModalIsOpen} onClose={closePasswordModal}>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                submit(e.target, 2);
              }}
            >
              <ModalContainer>
                <LabelContainer>
                  <label htmlFor="novaSenha">Nova senha:</label>
                  <Input
                    type="password"
                    id="novaSenha"
                    name="password"
                    onChange={handlePasswordInput}
                  />
                </LabelContainer>
                <LabelContainer>
                  <label htmlFor="confirmNovaSenha">
                    Confirme a nova senha:
                  </label>
                  <Input
                    type="password"
                    id="confirmNovaSenha"
                    name="confirmPassword"
                    onChange={handlePasswordInput}
                  />
                </LabelContainer>
                <div className="buttonsContainer">
                  <SafeButton
                    type="submit"
                    variant="primary"
                    loading={isLoading}
                  >
                    Alterar senha
                  </SafeButton>
                </div>
              </ModalContainer>
            </form>
          </Modal>
        </>
      )}

      <Modal
        open={showSignModal}
        onClose={() => {
          closeSignModal();
        }}
      >
        <ModalContainer>
          <h4>Desenhe sua assinatura</h4>
          <SignCanvas ref={canvasRef} style={{ borderRadius: "15px" }} />
          <div className="buttonsContainer">
            <SafeButton
              variant="primary"
              onClick={() => cleanSign(canvasRef.current)}
            >
              Limpar
            </SafeButton>
            <SafeButton
              variant="primary"
              loading={loading}
              onClick={() => {
                setLoading(true);
                sendData();
              }}
            >
              Confirmar
            </SafeButton>
          </div>
        </ModalContainer>
      </Modal>

      <Modal
        open={confirmSignFontModal}
        onClose={() => {
          setConfirmSignFontModal(false);
          setChosenFont();
        }}
      >
        <ModalContainer>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              submit(e.target, 3);
            }}
          >
            <LabelContainer>
              <label htmlFor="nome_completo_fonte">
                Digite o nome desejado:
              </label>
              <Input
                style={{ width: "100%" }}
                type="text"
                minLength={2}
                required
                id="nome_completo_fonte"
                name="text"
                className="button"
              />
            </LabelContainer>

            <div className="button">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  paddingTop: "1rem",
                  paddingBottom: "1rem",
                }}
              >
                <h5>Escolha a font da letra:</h5>
              </div>
              {Fonts.map((f) => (
                <Font
                  key={f.value}
                  font={f.name}
                  style={
                    chosenFont === f.value
                      ? { backgroundColor: `${colors.primary}` }
                      : { backgroundColor: `${colors.gray200}` }
                  }
                  type="button"
                  onClick={() => setChosenFont(f.value)}
                >
                  <p>{f.label}</p>
                </Font>
              ))}
            </div>

            <div className="buttonsContainer">
              <SafeButton
                variant="primary"
                type="button"
                onClick={() => {
                  setConfirmSignFontModal(false);
                  setChosenFont();
                }}
              >
                Cancelar
              </SafeButton>
              <SafeButton variant="primary" type="submit">
                Confirmar
              </SafeButton>
            </div>
          </form>
        </ModalContainer>
      </Modal>
    </>
  );
}
