/* eslint-disable react-hooks/exhaustive-deps */
import {Modal} from "@mui/material";
import {toast} from "react-toastify";
import SafeButton from "../SafeButton";
import Card from "../../components/Card";
import {MdCheck} from "react-icons/md";
import * as api from "../../services/api";
import {Pagination} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {useAuth} from "../../hooks/useAuth";
import React, {useEffect, useState} from "react";
import {currencyFormatter} from "../../utils/INTL";
import useWindowSize2 from "./../../hooks/useWindowSize2";
import {
    Container,
    PlanoColuna,
    Title,
    ModalContainer,
} from "./styles";
import {colors} from "../../styles/colors";
import {FaCheckCircle} from "react-icons/fa";
import {PaymentComponent} from "../PaymentComponent";
import { useUserSet } from "../../hooks/useUserSet";

export default function Plan(props) {
    const [page] = useState(1);
    const [plans, setPlans] = useState([]);
    const [response, setResponse] = useState();
    const [placeholder, setPlaceholder] = useState(true);
    const [currentPlan, setCurrentPlan] = useState();
    const [confirmChangeModal, setConfirmChangeModal] = useState(false);
    const [showPayment, setShowPayment] = useState(false);

    const {user} = useAuth();
    const navigate = useNavigate();
    const [width] = useWindowSize2();

    let chroma = 0;

    function getCurrentPage(currentPage) {
        let date = `${user.company !== null ? "&category=2" : "&category=1"}`;

        api
            .get(`/plan/list/?visibility=1&page=${currentPage}${date}`)
            .then((response) => {
                if (response?.status === 200) {
                    setPlans(response?.data.detail.results);
                    setResponse(response.data.detail.count);
                    setPlaceholder(false);
                } else {
                    toast.error(
                        "Não foi possivel resgatar a listagem de planos, tente novamente mais tarde",
                        {
                            position: toast.POSITION.TOP_RIGHT,
                        }
                    );
                }
            })
            .catch((error) => {
                toast.error(
                    "Não foi possivel resgatar a listagem de planos, tente novamente mais tarde",
                    {
                        position: toast.POSITION.TOP_RIGHT,
                    }
                );
            });
    }

    useUserSet()
    useEffect(() => {
        getCurrentPage(page);
    }, [page]);

    function closeConfirmChangeModal() {
        setConfirmChangeModal(false);
    }

    const statusNotPaid = ['0', '1', '4']

    const handlePlanClick = (plan) => async () => {
        if (user.company && user.profile === "2") {
            if (user.company_plan !== plan.id || statusNotPaid.indexOf(user.company_paid) > -1) {
                setCurrentPlan(plan);
                setConfirmChangeModal(true);
            }
        } else if (user.plan !== plan.id || statusNotPaid.indexOf(user.paid) > -1) {
            setCurrentPlan(plan);
            setConfirmChangeModal(true);
        } else {
            toast.warning("Você já contratou esse plano!", {
                position: toast.POSITION.TOP_RIGHT,
            })
        }
    };

    return (
        <Card>
            <Title>
                <p>Selecione um plano</p>
                <SafeButton
                    onClick={() => navigate(-1)}
                    variant="primary"
                >
                    Voltar
                </SafeButton>
            </Title>

            <Container
                style={{
                    flexDirection: width <= 900 ? "column" : "row",
                    alignItems: width <= 900 ? "center" : "",
                    flexWrap: width <= 900 ? "" : 'wrap',
                    margin: "1rem"
                }}
            >
                {plans.map((plan) => {
                    if (chroma > 3) {
                        chroma = 0;
                    } else {
                        chroma++;
                    }
                    const isSelected = user?.company
                        ? user?.company_plan === plan?.id
                        : user?.plan === plan?.id;

                    return (
                        <div key={plan?.id} style={{
                            border: isSelected ? ".4rem solid rgba(140, 230, 177, 0.9)" : "",
                            borderRadius: isSelected ? "20px" : "",
                            marginRight: isSelected ? "1rem" : "",
                            marginLeft: isSelected ? "1rem" : "",
                            display: isSelected ? "flex" : ""
                        }}>
                            <PlanoColuna
                                style={
                                    {
                                        width: "27rem",
                                        cursor: "pointer",
                                        borderColor: colors.primary,
                                    }
                                }
                                key={plan?.id}
                                chroma={chroma}
                                isSelected={isSelected}
                                onClick={handlePlanClick(plan)}
                            >
                                <div
                                    className="title"
                                    style={{
                                        backgroundColor: colors.primary,
                                        borderBottom: `3px solid ${colors.primary}`,
                                    }}
                                >
                                    <h2>{plan?.name}</h2>
                                </div>
                                <ul
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <li
                                        style={{
                                            fontSize: "1.7rem",
                                            width: "max-content",
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            textAlign: "center",
                                        }}
                                    >
                                        <MdCheck size={20}/>{" "}
                                        {plan?.users > 9999
                                            ? "+9999 usuários simultâneos."
                                            : plan?.users + " usuários simultâneos."}
                                    </li>
                                    {plan?.whatsapp_amount > 0 ? (
                                        <li style={{fontSize: "1.7rem"}}>
                                            <MdCheck size={20}/>{" "}
                                            {plan?.whatsapp_amount + " Envios de WhatsApp."}
                                        </li>
                                    ) : (
                                        ""
                                    )}
                                    {plan?.email_amount > 0 ? (
                                        <li style={{fontSize: "1.7rem"}}>
                                            <MdCheck size={20}/>{" "}
                                            {plan?.email_amount + " Envios de email."}
                                        </li>
                                    ) : (
                                        ""
                                    )}
                                    {plan?.sms_amount > 0 ? (
                                        <li style={{fontSize: "1.7rem"}}>
                                            <MdCheck size={20}/>{" "}
                                            {plan?.sms_amount + " Envios de SMS."}
                                        </li>
                                    ) : (
                                        ""
                                    )}
                                    <li style={{fontSize: "1.7rem"}}>
                                        <MdCheck size={20}/> {plan?.processes + " documentos."}
                                    </li>
                                </ul>
                                <p>{plan?.description}</p>
                                <h3>
                                    {!parseInt(plan.price)
                                        ? "Grátis"
                                        : currencyFormatter(plan?.price)}
                                    {!parseInt(plan.price)
                                        ? ""
                                        : plan?.plan_type === "1"
                                            ? "/mês"
                                            : "/ano"}
                                </h3>
                                <span style={{
                                    display: isSelected ? "flex" : "none",
                                    color: "rgba(30, 208, 51, 0.7)",
                                    marginBottom: isSelected ? "1rem" : ""
                                }}>
                                    <FaCheckCircle/>
                                </span>
                            </PlanoColuna>
                        </div>
                    );
                })}
            </Container>

            <div style={{marginTop: "20px"}} className="pageCount">
                <Pagination
                    count={response ? Math.ceil(response / 10) : 0}
                    variant="outlined"
                    shape="rounded"
                    onChange={(e, value) => getCurrentPage(value)}
                />
            </div>

            <Modal
                open={confirmChangeModal}
                onClose={() => closeConfirmChangeModal()}
            >
                <ModalContainer>
                    <div style={{fontSize: "2rem"}}>
                        <p style={{textAlign: "center"}}>
                            Confirma a contratação desse plano?
                        </p>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            paddingTop: "1rem",
                            justifyContent: "center",
                        }}
                    >
                        <div>
                            <SafeButton
                                variant="secondary"
                                onClick={closeConfirmChangeModal}
                            >
                                Cancelar
                            </SafeButton>
                        </div>
                        <div style={{paddingLeft: "0.5rem"}}>
                            <SafeButton
                                variant="primary"
                                onClick={() => {
                                    setShowPayment(true)
                                    setConfirmChangeModal(false)
                                }}
                            >
                                Confirmar
                            </SafeButton>
                        </div>
                    </div>
                </ModalContainer>
            </Modal>

            <Modal
                open={showPayment}
                onClose={() => {
                    setShowPayment(false)
                }}
                style={{justifyContent: 'center', display: 'flex', alignItems: 'center'}}
            >
                <div>
                    <PaymentComponent
                        plan_id={currentPlan?.id}
                        amount={currentPlan?.price}
                    />
                </div>
            </Modal>
        </Card>
    );
}
