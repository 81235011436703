import { useState } from 'react';
import { createContext} from 'react'

const PlanContext = createContext({});

export function PlanProvider({ children }) {

    const [ plan, setPlan ] = useState(null);

    return (
        <PlanContext.Provider
            value={{plan, setPlan}}
        >
            {children}
        </PlanContext.Provider>
    );
}

export default PlanContext;