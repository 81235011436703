import * as api from "../../services/api"
import {toast} from "react-toastify"
import {useAuth} from "../../hooks/useAuth"
import {Payment, initMercadoPago} from "@mercadopago/sdk-react"
import {useEffect} from "react"
import {useNavigate} from "react-router-dom"

export function PaymentComponent({plan_id, amount}) {
    const { user } = useAuth()
    const navigate = useNavigate()

    const executePaymente = async (token) => {
        try {
            // eslint-disable-next-line no-unused-vars
            await api.post('/payment/', {
                card_token: token,
                plan_id: plan_id
            }, "application/json").then((res) => {
                toast.dismiss()
                if (res.status === 201 || res.status === 200) {
                    toast.success(res.data.message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    setTimeout(() => {
                        navigate('/', {replace: true})
                    }, 3000)
                } else {
                    toast.error(res.response.data.message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            })
        } catch (error) {
            toast.dismiss()
            console.error('Erro ao criar assinatura:', error);
            toast.error("Falha ao realizar pagamento! Tente novamente.", {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    }

    const handlePayment = async (params) => {
        toast.loading("Realizando pagamento, por favor aguarde!", {
            position: toast.POSITION.TOP_RIGHT
        })
        const token = params.formData.token

        if (token) {
            await executePaymente(token)
        } else {
            toast.dismiss()
            toast.error("Falha ao realizar pagamento! Tente novamente mais tarde!", {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    };

    const customization = {
        paymentMethods: {
            // ticket: "all",
            // bankTransfer: "all",
            creditCard: "all",
            // debitCard: "all",
            // mercadoPago: "all",
            minInstallments: 1,
            maxInstallments: 1,
        },
        visual: {
            style: {
                customVariables: {
                    textPrimaryColor: "#000000",
                    textSecondaryColor: "#000000",
                    baseColor: "#60159a",
                    fontFamily: "Arial, sans-serif",
                    inputFontSize: "14px",
                },
            },
        },
        input: {
            email: {
                disabled: true,
            }
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            await executePaymente(null)
        }

        if (parseFloat(amount) === 0) {
            fetchData().then(r => {
            })
        } else if (parseFloat(amount) >= 1) {
            initMercadoPago("APP_USR-7753f538-b4ff-40e5-9dfb-0ce29b3c2d3b")
        }
    }, [amount, executePaymente])

    return (
        <div id="paymentBrick_container">
            {parseFloat(amount) > 0 && (
                <Payment
                    initialization={{
                        amount: amount,
                        payer: {
                            email: user.email,
                        }
                    }}
                    onReady={(response) => {
                    }}
                    onSubmit={handlePayment}
                    onError={(error) => {
                        console.error('Erro ao processar pagamento:', error);
                        toast.error("Erro ao processar pagamento. Verifique os dados e tente novamente.", {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                    }}
                    customization={customization}
                />            
            )}
        </div>
    )
}
