/* eslint-disable react-hooks/exhaustive-deps */
import { useRef } from 'react';
import { toast } from 'react-toastify';
import Modal from "@mui/material/Modal";
import { FiCheck } from 'react-icons/fi';
import { GrClose } from 'react-icons/gr';
import { DateRangePicker } from "rsuite";
import * as api from "../../services/api";
import { Pagination } from "@mui/material";
import { colors } from '../../styles/colors';
import Events from '../../components/Events';
import { ScaleLoader } from "react-spinners";
import { dateFormat } from "../../utils/INTL";
import { useNavigate } from 'react-router-dom';
import PDFShow from "../../components/PDFShow";
import { useAuth } from './../../hooks/useAuth';
// import { GoPrimitiveDot } from "react-icons/go";
import { useDash } from './../../hooks/useDash';
import { RiDeleteBin2Fill } from "react-icons/ri";
import { useParams, Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import SafeButton from '../../components/SafeButton';
import { Container, Title, Documento, Status, ModalContainer, Info, FilterSubMenu, CreatedBy, ModalContainerDelete } from "./styles";

export default function ProcessList({ mode, isTrash = false }) {

    const [info, setInfo] = useState({
        blocked: false,
        message: "",
        chroma: 0,
        processId: 0,
        index: 0,
        createdBy: {
            nome_sobrenome: "",
            email: "",
        },
    });
    const { isEmpty, setIsEmpty, getProcessCount } = useDash()
    const [response, setResponse] = useState()
    const [, setCertificadoData] = useState({
        blocked: true,
        processId: 0,
    });
    const [docModal, setDocModal] = useState({
        show: false,
        docId: 0,
        docUrl: "",
    });
    const [vaultName, setVaultName] = useState();
    const [processos, setProcessos] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const [isBlocked, setIsBlocked] = useState(false)
    const [currentPage, setCurrentPage] = useState(1);
    const [deleteModal, setDeleteModal] = useState(false)
    const [cancelModal, setCancelModal] = useState(false)
    const [currentProcess, setCurrentProcess] = useState([])

    const dates = useRef({
        initialDate: '',
        finalDate: ''
    })
    const { user } = useAuth();
    const navigate = useNavigate();
    const { status, id } = useParams();

    let parameter =
        status === 'all'
            ? ''
            : status === 'pending'
                ? '&status=1'
                : status === 'finalized'
                    ? '&status=2'
                    : status === 'canceled'
                        ? '&status=3'
                        : status === 'delete'
                            ? '&status=4'
                            : ''

    const deleteMod = (
        <>
            <ModalContainerDelete>
                <div className='content'>
                    <h4>Tem certeza que deseja remover este documento?</h4><br></br>
                    <div className='buttons'>
                        <SafeButton variant='secondary' onClick={() => { setDeleteModal(false) }}><GrClose color="#60159a" size={20} /></SafeButton>
                        <SafeButton variant='primary' onClick={() => { deleteProcess(currentProcess.id) }}><FiCheck color="#fff" size={20} /></SafeButton>
                    </div>
                </div>
            </ModalContainerDelete>
        </>
    )

    const cancelMod = (
        <>
            <ModalContainerDelete>
                <div className='content'>
                    <h4>Tem certeza que deseja cancelar este documento?</h4><br></br>
                    <div className='buttons'>
                        <SafeButton variant='secondary' onClick={() => { setCancelModal(false) }}><GrClose color="#60159a" size={20} /></SafeButton>
                        <SafeButton variant='primary' onClick={() => {
                            api
                                .put(`/process/cancel/${currentProcess.id}/`)
                                .then(response => {
                                    closeModal()
                                    setCancelModal(false)
                                    if (response?.status === 200) {
                                        getProcessCount()
                                        toast.success('Processo cancelado com sucesso', { position: toast.POSITION.TOP_RIGHT })
                                    } else if (response?.status === 400) {
                                        toast.error(response?.data?.detail, { position: toast.POSITION.TOP_RIGHT })
                                    } else {
                                        toast.error('Erro ao cancelar processo', { position: toast.POSITION.TOP_RIGHT })
                                    }
                                    getCurrentPage(currentPage)
                                })
                                .catch((error => {
                                    toast.error('Erro ao cancelar processo', { position: toast.POSITION.TOP_RIGHT })
                                }))
                        }}><FiCheck color="#fff" size={20} /></SafeButton>
                    </div>
                </div>
            </ModalContainerDelete>
        </>
    )

    function getProcess(item) {
        if (mode === true && isTrash === false) {
            setIsLoaded(false)
            api.get(`/process/user/?page=${item}&${parameter}`)
                .then((response) => {
                    if (response.status === 200) {
                        if (response.data.detail.count === 0) setIsEmpty(true)
                        setProcessos(response.data.detail.results)
                        setResponse(response.data.detail.count)
                        setIsLoaded(true)
                    }
                })
                .catch((error) =>
                    toast.error('Ocorreu um erro ao requerir os processos, tente novamente mais tarde', {
                        position: toast.POSITION.TOP_RIGHT
                    }))
        } else if (mode === false && isTrash === true) {
            setIsLoaded(false)
            api.get(`/process/trash/list/?page=${item}`)
                .then((response) => {
                    if (response.status === 200) {
                        setIsLoaded(true)
                        if (response?.data.detail.count === 0) setIsEmpty(true)
                        setProcessos(response?.data.detail.results)
                        setResponse(response?.data.detail.count)
                    }
                })
                .catch((error) => {
                    toast.error('Ocorreu um erro ao requerir os processos, tente novamente mais tarde', {
                        position: toast.POSITION.TOP_RIGHT
                    })
                })

        }else {
            setIsLoaded(false)
            api.get(`/process/vault/list/${id}/?page=${item}`)
                .then((response) => {
                    if (response.status === 200) {
                        if (response?.data.detail.count !== 0) {
                            setResponse(response.data.detail.count)
                            setProcessos(response.data.detail.results)
                            setIsLoaded(true)
                        } else {
                            setIsLoaded(true)
                            setIsEmpty(true)
                        }
                    }
                })
                .catch((error) => toast.error('Ocorreu um erro ao requerir os processos, tente novamente mais tarde', {
                    position: toast.POSITION.TOP_RIGHT
                }))

            api.get(`/vault/retrieve/${id}/`)
                .then((response) => {
                    setVaultName(response.data.detail.name)
                })
        }
    }

    useEffect(() => {
        getProcess(1)
    }, [status])

    async function deleteProcess(id) {
        await api.destroy(`/process/destroy/${id}/`)
            .then((response) => {
                closeModal()
                setDeleteModal(false)
                if (response?.response?.status === 404) {
                    toast.error('Este processo já foi deletado', { position: toast.POSITION.TOP_RIGHT })
                } else {
                    getProcessCount()
                    toast.success('Processo deletado com sucesso', { position: toast.POSITION.TOP_RIGHT })
                }
            })
            .catch((error) => {
                closeModal()
                getCurrentPage(currentPage)
                setDeleteModal(false)
                toast.error('Ocorreu um erro ao deletar o processo', { position: toast.POSITION.TOP_RIGHT })
            })
        getCurrentPage(currentPage)
    }

    async function showDoc(processo, index) {
        const { id: processId } = processo;
        const { id: docId, final_file } = processo.documents[0];
        let currentSigner = processo.signatories.find(
            (assinatura) => assinatura.email === user.email
        );
        let isSigner = true;

        if (currentSigner == null) {
            currentSigner = user;
            isSigner = false;
        }

        const isCreatedByUser =
            processo.created_by.email === user.email ? user.email : false;

        const orderedSigner = processo.signatories.find(
            (signatario) => signatario.assinado === false
        );

        if (isSigner) {
            if (processo.ordenado) {
                if (currentSigner.email === orderedSigner?.email) {
                    if (isCreatedByUser === currentSigner) {
                        // verificaAssinatura(currentSigner.assinado);
                    } else {
                        if (currentSigner) {
                            // verificaAssinatura(currentSigner.assinado);
                        }
                    }
                } else if (currentSigner.assinado && !processo.finalizado) {
                    setInfo({
                        blocked: true,
                        message:
                            "Aguarde todos os signátarios assinarem para poder acessar o certificado.",
                        chroma: 0,
                        processId,
                        index,
                        createdBy: processo.criado_por,
                    });
                } else if (!processo.finalizado) {
                    setInfo({
                        blocked: true,
                        message: "Aguarde Sua vez de assinar!",
                        chroma: 0,
                        processId,
                        index,
                        createdBy: processo.criado_por,
                    });
                } else {
                    setInfo({
                        blocked: true,
                        message: "Certificado disponível!",
                        chroma: 0,
                        processId,
                        index,
                        createdBy: processo.criado_por,
                    });
                }
            } else {
                // verificaAssinatura(currentSigner.assinado);
            }
        } else {
            setInfo({
                blocked: true,
                message: "Você não assina este documento.",
                chroma: 0,
                processId,
                index,
                createdBy: processo.criado_por,
            });
        }

        setDocModal({
            show: true,
            docId,
            docUrl: final_file,
            index,
            processId,
        });

        if (processo.finalizado) {
            setCertificadoData({ blocked: false, processId });
        }
    }

    async function redirectToAssinar() {
        let token = ''
        currentProcess.signatories.forEach((process) => {
            if (process.email === user.email) {
                token = process.token
            }
        })
        navigate(`/signature/${currentProcess.id}/${token}`)
    }

    async function redirectToPDF() {
        const newWindow = window.open(docModal.docUrl);
        if (newWindow) newWindow.opener = null;
    }

    function closeModal() {
        setDocModal({
            show: false,
            docId: 0,
            docUrl: "",
            isAssinado: false,
            isRecusado: false,
        });

        setCertificadoData({ blocked: true, processId: 0 });
    }

    async function getCurrentPage(item, initialDate = '', finalDate = '') {
        let yes = false
        setIsLoaded(false)
        await api.get(`/process/user/?page=${item}${parameter}${initialDate}${finalDate}`)
            .then((response) => {
                if (response.status === 200) {
                    setProcessos(response.data.detail.results)
                    yes = true
                    if (yes === true) {
                        setResponse(response.data.detail.count)
                        setIsLoaded(true)
                    }
                }
            })
            .catch((error) => {
                toast.error(`Ocorreu um erro ao tentar requerir os processos da pagina, tente novamente mais tarde`, { position: toast.POSITION.TOP_RIGHT })
            })
    }

    function insertDateSubFilters(e) {
        dates.current = {
            initialDate: `&initial_date=${e[0].toISOString().substring(0, 10)}`,
            finalDate: `&final_date=${e[1].toISOString().substring(0, 10)}`
        }
        getCurrentPage(1, dates.current.initialDate, dates.current.finalDate)
    }

    const body = (
        <>
            {isLoaded && !isEmpty && (
                <ModalContainer>
                    <aside>
                        <CreatedBy>
                            Processo criado por:
                            <br />
                            {currentProcess.created_by?.name} ({currentProcess.created_by?.email})
                        </CreatedBy>
                        <Events
                            style={{width:"100%"}}
                            signatarios={currentProcess?.signatories}
                            processId={currentProcess?.id}
                            isCreatedByUser={currentProcess.created_by?.email === user.email}
                        />

                        {
                            (() => {
                                let pass = false
                                currentProcess.signatories?.forEach((item) => {
                                    if (item.email === user.email) pass = true
                                })
                                if (currentProcess?.orderly === true && pass === true) {
                                    let order = -1
                                    currentProcess?.signatories?.forEach((item) => {
                                        if (item.signed === true) {
                                            order += 1
                                        }
                                    })
                                    let myOrd = currentProcess.signatories.findIndex((item) => item.email === user.email)
                                    if (myOrd - 1 === order) return
                                    else if (myOrd === order || myOrd < order) return <Info chroma={1}>Você já assinou este processo</Info>
                                    else return <Info chroma={info.chroma}>Ainda não é sua vez de assinar!</Info>
                                }
                                else if (
                                    pass === true &&
                                    currentProcess.signatories[currentProcess.signatories.findIndex((item) =>
                                        item.email === user.email)].signed === true) {
                                    return <Info chroma={1}>Você já assinou este processo</Info>
                                }
                                else if (pass === true) return ''
                                else return <Info chroma={info.chroma}>{info.message}</Info>
                            })()
                        }
                        {
                            (() => {
                                let myOrd = currentProcess.signatories?.findIndex((item) => item.email === user.email)
                                let pass = false
                                let currentUser = {}
                                currentProcess.signatories?.forEach((item) => {
                                    if (item.email === user.email) {
                                        pass = true
                                        currentUser = item
                                    }
                                })
                                if (pass === true && currentProcess.status !== '3' && currentProcess.status !== '2') {
                                    let order = -1
                                    currentProcess?.signatories?.forEach((item) => {
                                        if (item.signed === true) {
                                            order += 1
                                        }
                                    })
                                    if (myOrd - 1 !== order && currentProcess.orderly === true) {
                                        return ''
                                    } else if (currentProcess.status === '4' || currentUser?.signed === true){
                                        return ''
                                    } else {
                                        return (
                                            <SafeButton
                                                variant='primary'
                                                style={{
                                                    width: "100%",
                                                    marginTop: "10px",
                                                }}
                                                onClick={redirectToAssinar}
                                            >
                                                Assinar
                                            </SafeButton>
                                        )
                                    }
                                }
                                else return
                            })()
                        }
                        {
                            currentProcess.status === '2'
                                ? <SafeButton
                                    variant='primary'
                                    style={{
                                        width: "100%",
                                        marginTop: "10px",
                                    }}
                                    onClick={() => {
                                        window.open(`/certificate/${currentProcess?.certificate?.id}/${currentProcess?.certificate?.hash}`)
                                    }}
                                >
                                    Acessar Certificado
                                </SafeButton>
                                : ''
                        }
                        {currentProcess.status === '1' && currentProcess?.created_by.id === user.id && 
                        <SafeButton
                            loading={isLoading}
                            blocked={isBlocked}
                            style={{
                                width: "100%",
                                marginTop: "10px",
                            }}
                            onClick={() => {
                                setIsLoading(true)
                                api.get(`/signatures/resend/sign/${currentProcess.id}/`)
                                    .then((response) => {
                                        if (response?.status) {
                                            setIsLoading(false)
                                            setIsBlocked(true)
                                            toast.success(currentProcess?.orderly === true ? 'E-mail reenviado ao próximo signatário' : 'E-mails reenviados', {
                                                position: toast.POSITION.TOP_RIGHT
                                            })
                                        } else {
                                            setIsLoading(false)
                                            toast.error('Ocorreu um erro ao reenviar os e-mails', {
                                                position: toast.POSITION.TOP_RIGHT
                                            })
                                        }
                                    })
                                    .catch((error) => {
                                        toast.error('Ocorreu um erro ao reenviar os e-mails', {
                                            position: toast.POSITION.TOP_RIGHT
                                        })
                                    })
                            }}
                        >
                            Reenviar e-mails
                        </SafeButton>}
                        <SafeButton
                            style={{
                                width: "100%",
                                marginTop: "10px",
                            }}
                            onClick={redirectToPDF}
                        >
                            Abrir documento em nova aba
                        </SafeButton>
                        {user?.id === currentProcess?.created_by?.id && currentProcess.status === '1' ? (
                            <SafeButton
                                variant='secondary'
                                style={{
                                    width: '100%',
                                    marginTop: '10px',
                                }}
                                onClick={() => {
                                    setCancelModal(true)
                                }}
                            >
                                Cancelar processo
                            </SafeButton>
                        ) : null}
                        {currentProcess.status === '1' && user?.id === currentProcess?.created_by?.id
                            ?
                            <SafeButton
                                variant='tertiary'
                                style={{
                                    width: '100%',
                                    marginTop: '10px',
                                }}
                                onClick={() => {
                                    setDeleteModal(true)
                                }}
                            >
                                <RiDeleteBin2Fill
                                    size={25}
                                />
                                <p>Excluir processo</p>
                            </SafeButton>
                            :
                            ''
                        }
                    </aside>
                    {docModal.show && (
                        <PDFShow documentUrl={docModal.docUrl} isLoaded={docModal.show} />
                    )}
                </ModalContainer>
            )}
        </>
    );

    return (
        <>
            {mode === true || isTrash === true ? '' : <Title>
                <p>Cofre: <span>{vaultName}</span></p>
                <Link to="/vaults">
                    <SafeButton variant='primary'>Voltar</SafeButton>
                </Link>
            </Title>}
            {
                mode === true
                    ? <FilterSubMenu>
                        <div>
                            <div id="data-range" style={{ paddingTop: "0.5rem" }}>
                                <DateRangePicker
                                    key="data"
                                    showOneCalendar
                                    format='DD/MM/YYYY'
                                    style={{ width: 224 }}
                                    onOk={insertDateSubFilters}
                                    onClean={() => {
                                        dates.current = {
                                            initialDate: '',
                                            finalDate: ''
                                        }
                                        getCurrentPage(currentPage, '', '')
                                    }}
                                    placeholder="Pesquisar no intervalo"
                                />
                            </div>
                            {/* <div style={{ paddingTop: "0.5rem" }}>
                                <SelectPicker
                                    style={{ width: 224 }}
                                    placeholder="Signatário"
                                    onChange={insertSignatarioSubFilters}
                                    data={signatarios}
                                />
                            </div> */}
                        </div>
                    </FilterSubMenu>
                    : ''
            }

            <Container>
                {mode === false && isTrash === true && <p style={{backgroundColor:colors.gray300, padding:'10px', borderRadius:'15px'}}>
                    Os processos listados abaixo serão excluídos permanentemente após 30 dias, da data de exclusão.
                </p>}
                {isLoaded ? (
                    isEmpty ? (
                        <p style={{ padding: "1rem" }}>
                            Não há processos por enquanto.
                        </p>
                    ) : (
                        
                        processos.map((processo, index) => (
                            <Documento
                                key={processo.id}
                                onClick={() => {
                                    showDoc(processo, index)
                                    setCurrentProcess(processo)
                                }}
                            >
                                <Status chroma={
                                    processo.status === '1'
                                        ? 1
                                        : processo.status === '2'
                                            ? 2
                                            : processo.status === '3'
                                                ? 3
                                                : 4
                                }
                                >
                                    {/* <GoPrimitiveDot size={20} /> */}
                                    <p>
                                        {
                                            processo.status === '1'
                                                ? 'Pendente'
                                                : processo.status === '2'
                                                    ? 'Finalizado'
                                                    : processo.status === '3'
                                                        ? 'Cancelado'
                                                        : 'Excluído'
                                        }
                                    </p>
                                </Status>
                                <strong>
                                    {
                                        processo.name
                                    }
                                </strong>
                                <div className="space">
                                    <p>
                                        <b>Enviado por:</b> <br />{" "}
                                        {processo.created_by.name} <br />{" "}
                                        {`(${processo.created_by.email})`}
                                    </p>
                                </div>
                                <p>{dateFormat(processo.created_at)}</p>
                            </Documento>
                        ))
                    )
                ) : (
                    <ScaleLoader
                        color="#60159a"
                        width="10px"
                        height="60px"
                        margin="6px"
                        radius="10px"
                    />
                )}
                <div style={{ marginTop: '10px' }} className="pageCount">
                    <Pagination
                        count={Math.ceil(parseInt(response) / 10) || 0}
                        variant="outlined"
                        shape="rounded"
                        onChange={(e, value) => {
                            setCurrentPage(value);
                            mode === true
                                ? getCurrentPage(value, dates.current.initialDate, dates.current.finalDate)
                                : getProcess(value);
                        }
                        }
                    />
                </div>
            </Container>

            <Modal
                open={deleteModal}
                onClose={() => setDeleteModal(false)}
                aria-labelledby="modal-modal-title1"
                aria-describedby="modal-modal-description1"
            >
                {deleteMod}
            </Modal>

            <Modal
                open={cancelModal}
                onClose={() => setCancelModal(false)}
                aria-labelledby="modal-modal-title1"
                aria-describedby="modal-modal-description1"
            >
                {cancelMod}
            </Modal>

            <Modal
                open={docModal.show}
                onClose={() => {
                    closeModal()
                    setIsBlocked(false)
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                {body}
            </Modal>
        </>
    )
}